import React from "react";
import PhoneIcon from "../../assets/icons/Phone.svg"
import PalmIcon from "../../assets/icons/Hand.svg"
import CardIcon from "../../assets/icons/Card.svg"

const LogComponent = ({ pops, type, Name }) => {
    const formatTimestamp = (timestamp) => {
        if (!timestamp || typeof timestamp.seconds === "undefined") {
            return "Invalid Timestamp";
        }

        const date = new Date(timestamp.seconds * 1000);
        const options = { month: "short", year: "numeric", hour: "numeric", minute: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const combineArrayFields = (items, amounts, prices) => {
      if (!items || !amounts || !prices) return "";
      return items.map((item, index) => {
        const amount = amounts[index] || "0";
        const price = prices[index] || "0.00";
        return `${item} (${amount} @ $${price})`;
      }).join(", "); // Ensure commas inside the field are enclosed in quotes in the CSV
    };

    const renderTypeSpecificContent = (data) => {
        switch (type) {
            case "Locks":
                return (
                    <div className="flex-grow h-full flex flex-col pb-4 cursor-default">
                        <div className="h-8 items-center w-full flex text-sm">
                            <p className="font-semibold mr-1">{data.User || "Unknown User"}</p>unlocked {Name}
                            <p className="font-medium mx-1 opacity-50">{"•"}</p>
                            <p className="ml-1 opacity-50">{formatTimestamp(data.Timestamp)}</p>
                        </div>
                        <div className="flex-grow flex flex-col w-full border bg-white rounded-xl shadow-sm hover:shadow-md transition overflow-clip">
                            <div className={"h-fit border-b w-full px-4 xl:px-7 py-1 flex justify-between"}>
                                <div className={"text-xs font-medium opacity-50 flex-grow"}>Access Level</div>
                                <div className={"text-xs font-medium opacity-50 flex space-x-2 w-fit"}>
                                    <div className={"w-48"}>Type</div>
                                    <div className={"w-48"}>ID</div>
                                </div>
                            </div>
                            <div className={"flex-grow w-full px-1 py-1 flex justify-between space-x-2"}>
                                <div
                                    className={"text-sm font-medium opacity-50 h-full flex-grow rounded-lg transition pl-3.5 xl:pl-6 flex items-center"}>{data.UserLevel || "Unknown Level"}</div>
                                <div className={"text-sm font-medium opacity-50 flex space-x-2 w-fit pr-3.5 xl:pr-6"}>
                                    <div className={"w-48 flex items-center"}>{data.PopType || "No Type"}</div>
                                    <div className={"w-48 flex items-center"}>{data.id || "No ID"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "Registers":
                return (
                    <div className="flex-grow h-full flex flex-col pb-4 cursor-default">
                        <div className="h-8 items-center w-full flex text-sm">
                            <p className="font-semibold mr-1">{data.User || "Unknown User"}</p>purchased from {Name}
                            <p className="font-medium mx-1 opacity-50">{"•"}</p>
                            <p className="ml-1 opacity-50">{formatTimestamp(data.Timestamp)}</p>
                        </div>
                        <div className="flex-grow flex flex-col w-full border bg-white rounded-xl shadow-sm hover:shadow-md transition overflow-clip">
                            <div className={"h-fit border-b w-full px-4 xl:px-7 py-1 flex justify-between"}>
                                <div className={"text-xs font-medium opacity-50 flex-grow"}>Items</div>
                                <div className={"text-xs font-medium opacity-50 flex space-x-2 w-fit"}>
                                    <div className={"w-48"}>Price</div>
                                    <div className={"w-48"}>ID</div>
                                </div>
                            </div>
                            <div className={"flex-grow w-full px-1 py-1 flex justify-between space-x-2"}>
                                <div className={"text-sm font-medium opacity-50 h-full flex-grow rounded-lg transition pl-3.5 xl:pl-6 flex items-center"}>
                                    {combineArrayFields(data.ItemsPurchased, data.Amounts, data.Prices)}
                                </div>
                                <div className={"text-sm font-medium opacity-50 flex space-x-2 w-fit pr-3.5 xl:pr-6"}>
                                    <div className={"w-48 flex items-center"}>${data.PurchaseAmount || "Unknown"}</div>
                                    <div className={"w-48 flex items-center"}>{data.id || "No ID"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "Checkpoints":
                return (
                    <div className="flex-grow h-full flex flex-col pb-4 cursor-default">
                        <div className="h-8 items-center w-full flex text-sm">
                            <p className="font-medium mr-1">{data.User || "Unknown User"} reached a checkpoint</p>
                            <p className="ml-1 opacity-50">{formatTimestamp(data.Timestamp)}</p>
                        </div>
                        <div
                            className="flex-grow flex flex-col w-full border bg-white rounded-xl shadow-sm hover:shadow-md transition overflow-clip">
                            <div className="h-fit border-b w-full px-4 xl:px-7 py-1 flex justify-between">
                                <div className="text-xs font-medium opacity-50 flex-grow">Checkpoint ID</div>
                                <div className="text-xs font-medium opacity-50">{data.CheckpointID || "N/A"}</div>
                            </div>
                            <div className="p-4 text-sm">Additional checkpoint-specific details here.</div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="flex-grow h-full flex flex-col pb-4 cursor-default">
                        <div className="h-8 items-center w-full flex text-sm">
                            <p className="font-medium mr-1">{data.User || "Unknown User"} performed an action</p>
                            <p className="ml-1 opacity-50">{formatTimestamp(data.Timestamp)}</p>
                        </div>
                        <div
                            className="flex-grow flex flex-col w-full border bg-white rounded-xl shadow-sm hover:shadow-md transition overflow-clip">
                            <div className="h-fit border-b w-full px-4 xl:px-7 py-1 flex justify-between">
                                <div className="text-xs font-medium opacity-50 flex-grow">Details</div>
                                <div className="text-xs font-medium opacity-50">{data.Details || "N/A"}</div>
                            </div>
                            <div className="p-4 text-sm">Additional generic details here.</div>
                        </div>
                    </div>
                );
        }
    };

    const renderPopType = (type) => {
        if(type === "Phone Code"){
            return <img src={PhoneIcon} alt={type} className={"w-full aspect-square border rounded-full bg-white p-1"}/>
        }
        if(type === "Palm Scan"){
            return <img src={PalmIcon} alt={type} className={"w-full aspect-square border rounded-full bg-white p-1"}/>
        }
        if(type === "Card"){
            return <img src={CardIcon} alt={type} className={"w-full aspect-square border rounded-full bg-white p-1.5 overflow-visible"}/>
        }


        return <div className="w-full aspect-square bg-white border rounded-full"></div>
    }

    return (
        <div className="h-fit flex flex-col font-Poppins relative">
            {pops.length > 0 ? (
                pops.map((data, index) => (
                    <div key={index} className="h-32 w-full flex space-x-4">
                        <div className="w-8 h-full flex flex-col relative items-center">
                            {renderPopType(data.PopType)}
                            <div className="h-48 w-[1px] bg-slate-200 rounded-full"></div>
                        </div>
                        {renderTypeSpecificContent(data)}
                    </div>
                ))
            ) : (
                <div className="flex items-center justify-center w-full h-64">
                    <p className="text-sm text-gray-500">No data available for this section.</p>
                </div>
            )}
            <div className={"absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-slate-50 h-3"}></div>
        </div>

    );
};

export default LogComponent;
