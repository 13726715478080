import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "./Table";
import {
  fetchSites,
  fetchAllLocksBySiteID
} from "../../services/firestoreService";
import { ReactComponent as OwnerAccess } from "../../assets/icons/OwnerIcon.svg";
import { ReactComponent as StudentAccess } from "../../assets/icons/StudentIcon.svg";
import { ReactComponent as PublicAccess } from "../../assets/icons/PublicIcon.svg";
import {addDoc, collection} from "@firebase/firestore";
import {db} from "../../firebaseConfig";
import {IoClose} from "react-icons/io5";

const LockList = ({ orgId }) => {
  const [locks, setLocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getHardware = async () => {
      const sites = await fetchSites(orgId);
      const hardwarePromises = sites.map(async (site) => {
        const hardware = await fetchAllLocksBySiteID(orgId, site.id);
        return hardware.map((h) => ({ ...h, siteName: site.Name }));
      });
      const hardwareData = await Promise.all(hardwarePromises);
      setLocks(hardwareData.flat());
      setLoading(false);
    };

    getHardware();
  }, [orgId]);

  const getAccessLevelIcon = (AccessLevel) => {
    if (AccessLevel === "Primary") {
      return <OwnerAccess />;
    } else if (AccessLevel === "Secondary") {
      return <StudentAccess />;
    } else {
      return <PublicAccess />;
    }
  };

  const fetchSiteNames = async () => {
    // Example fetch function for site names
    const siteData = await fetchSites(orgId); // Replace with actual logic
    return siteData.reduce((acc, site) => {
      acc[site.id] = site.Name;
      return acc;
    }, {});
  };

  const AddLock = ({ closeMenu }) => {
      const [isVisible, setIsVisible] = useState(false);
      const [loading, setLoading] = useState(false);
      const [sites, setSites] = useState([]);
      const [Name, setName] = useState("");
      const [selectedSite, setSelectedSite] = useState("");
      const [accessLevel, setAccessLevel] = useState("");
      const [openTime, setOpenTime] = useState("");
      const [closeTime, setCloseTime] = useState("");


      useEffect(() => {
        const fetchSitesData = async () => {
          if (!orgId) return;
          try {
            const sitesList = await fetchSites(orgId);
            setSites(sitesList);
            setIsVisible(true); // Trigger animation on mount
          } catch (error) {
            console.error("Error fetching sites:", error);
          }
        };

        fetchSitesData();
      }, [orgId]);


      const handleClose = () => {
        setIsVisible(false); // Trigger closing animation
        setTimeout(() => {
          closeMenu(); // Close menu after animation
          reloadLocks(); // Reload registers
        }, 300);
      };

      const reloadLocks = async () => {
        if (!orgId) return;
        try {
          const sites = await fetchSites(orgId);
          const hardwarePromises = sites.map(async (site) => {
            const hardware = await fetchAllLocksBySiteID(orgId, site.id);
            return hardware.map((h) => ({ ...h, siteName: site.Name }));
          });
          const hardwareData = await Promise.all(hardwarePromises);
          setLocks(hardwareData.flat());
        } catch (error) {
          console.error("Error reloading registers:", error);
        }
      };


      const handleCreate = async () => {
        if (!Name || !selectedSite || !accessLevel || !openTime || !closeTime) {
          alert("Please fill in all fields.");
          return;
        }

        const capitalizedName = Name.charAt(0).toUpperCase() + Name.slice(1);

        const newLock = {
          Name: capitalizedName,
          Site: selectedSite,
          AccessLevel: accessLevel,
          OpenTime: openTime,
          CloseTime: closeTime,
        };

        console.log("Creating lock with data:", newLock);

        try {
          const locksRef = collection(db, `organization_data/${orgId}/sites/${selectedSite}/locks`);
          await addDoc(locksRef, newLock);
          handleClose();
        } catch (error) {
          console.error("Error creating lock:", error);
          alert("Failed to create lock.");
        }
      };


      return (
        <div
          className={`fixed inset-0 bg-[#00000050] h-full w-screen z-50 flex items-center justify-center transition-opacity duration-300 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
              className={`bg-white w-96 h-fit rounded-lg shadow-lg flex flex-col transform transition-transform duration-300 overflow-clip ${
                  isVisible ? "translate-y-0" : "translate-y-10"
              }`}
          >
            <div className="h-fit w-full border-b py-4 px-4 flex justify-between items-center">
              <h1 className="font-semibold text-slate-700">Add Register</h1>
              <div
                  className="w-fit h-fit cursor-pointer hover:bg-slate-100 p-1 rounded flex items-center justify-center transition"
                  onClick={handleClose}
              >
                <IoClose/>
              </div>
            </div>

            <div className="h-fit w-full px-6 py-6 flex flex-col space-y-4">
              {/* Name Input */}
              <div>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Lock Name
                </label>
                <input
                    type="text"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter lock name"
                    className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Site Dropdown */}
              <div>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Site
                </label>
                <select
                    value={selectedSite}
                    onChange={(e) => setSelectedSite(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="">Select a site</option>
                  {sites.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.Name}
                      </option>
                  ))}
                </select>

              </div>

              <div>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Access Level
                </label>
                <select
                    value={accessLevel}
                    onChange={(e) => setAccessLevel(e.target.value)} // Use `e.target.value` for the selected value
                    className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="">Select an Access Level</option>
                  <option value="Primary">Primary</option>
                  <option value="Secondary">Secondary</option>
                  <option value="Public">Public</option>
                </select>
            </div>
            <div className={"flex w-full justify-between space-x-2"}>
              {/* Open Time */}
              <div className={"flex-1"}>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Open Time
                </label>
                <input
                    type="time"
                    value={openTime}
                    onChange={(e) => setOpenTime(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>

                {/* Close Time */}
                <div className={"flex-1"}>
                  <label className="block text-sm font-medium text-slate-600 mb-1">
                    Close Time
                  </label>
                  <input
                      type="time"
                      value={closeTime}
                      onChange={(e) => setCloseTime(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div>


            <div className="h-fit w-full border-t bg-slate-50 p-4 flex justify-end space-x-2 items-center">
              <button
                  className="w-fit h-fit text-slate-700 text-sm font-medium hover:bg-slate-200 py-2 px-4 rounded-lg transition"
                  onClick={handleClose}
              >
                Cancel
              </button>
              <button
                  className={`w-fit h-fit px-4 text-white py-2 text-sm font-medium rounded-lg transition ${
                      loading ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-500"
                  }`}
                  onClick={handleCreate}
                  disabled={loading}
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      );
  };

  const renderRow = (item) => {
    const handleRowClick = () => {
      navigate(`details/${item.id}`, {state: {item}});
    };

    return (
        <tr
            key={item.id} // Ensure `item.id` is unique
            className={`cursor-pointer hover:bg-slate-50 transition border-b border-slate-100`}
            onClick={handleRowClick}
        >
          <td className="py-3 w-2 p-2 scale-75"></td>
          <td className="py-3 w-10 p-2 scale-75">{getAccessLevelIcon(item.AccessLevel)}</td>
          <td className="py-3 w-24 text-sm opacity-50 uppercase">{item.id.toString().substring(0, 4) + "-" + item.id.toString().substring(4, 8)}</td>
          <td className="py-3 flex-grow text-sm font-medium pl-2">{item.Name}</td>
          <td className="py-3 flex-grow flex justify-end mr-6">
            <div className="h-fit w-fit border rounded-full text-xs py-1 px-2 bg-white">
              {item.siteName}
            </div>
          </td>
        </tr>
    );
  };

  return (
      <Table
          name={"Locks"}
          data={locks}
          loading={loading}
          groupByOptions={[{value: "siteName", label: "Site"}]}
          sortOptions={[{value: "Name", label: "Name"}]}
          filterOptions={[{value: "siteName", label: "Site"}]}
          onGroupBy={(option) => console.log("Group By:", option)}
          onSort={(option) => console.log("Sort By:", option)}
          onFilterBy={(option) => console.log("Filter By:", option)}
          renderRow={renderRow} // Pass row renderer
          RenderAdd={AddLock}
          fetchSiteNames={fetchSiteNames}
      />
  );
};

export default LockList;
