import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Update the path to your Firebase config
import SuccessIcon from "../../assets/icons/CheckMarkWhite.svg"
import FailIcon from "../../assets/icons/PlusWhite.svg"
import SecurityIcon from "../../assets/icons/LockIconBlack.svg";
import SalesIcon from "../../assets/icons/SalesIconBlack.svg"; // Example icon for Sales
import CheckInsIcon from "../../assets/icons/DevicesBlack.svg"
import RecieveIcon from "../../assets/icons/Enter.svg"

const DataSendTable = ({ orgId, recipientId, recipientName }) => {
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (!orgId || !recipientId) {
        console.error("Organization ID and Recipient ID are required.");
        setLoading(false);
        return;
      }

      try {
        // Query the 'historical_data_sends' subcollection
        const historicalDataRef = collection(db, `organization_data/${orgId}/historical_data_sends`);
        const q = query(historicalDataRef, where("Recipient", "==", recipientId));
        const querySnapshot = await getDocs(q);

        // Map through the documents and convert timestamps
        const data = querySnapshot.docs.map((doc) => {
          const docData = doc.data();
          return {
            id: doc.id,
            ...docData,
            Date: docData.Date?.toDate().toLocaleString() || "Unknown Date", // Convert timestamp to string
          };
        });
        setHistoricalData(data);
      } catch (error) {
        console.error("Error fetching historical data sends:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoricalData();
  }, [orgId, recipientId]);

  const renderStatusIcon = (status) => {
    if (status) {
      return <img className={"h-5 w-5 p-0.5 rounded-full bg-green-800"} src={SuccessIcon}/>
    } else {
      return <img className={"h-5 w-5 p-0.5 rounded-full bg-red-800 rotate-45"} src={FailIcon}/>
    }
  }

  const renderTypes = (types) => {
    if (!types || types.length === 0) {
      return <div className="text-gray-500">No types available</div>;
    }

    // Define a mapping of types to icons and colors
    const typeIcons = {
      Security: { icon: SecurityIcon, color: "bg-[#B2CAF650]" },
      Sales: { icon: SalesIcon, color: "bg-[#556B2F20]" },
      "Check-Ins": { icon: CheckInsIcon, color: "bg-[#DAA52030]" },
    };

    return (
      <div className="flex space-x-2">
        {types.map((type, index) => {
          const typeConfig = typeIcons[type] || {
            icon: SecurityIcon, // Default icon if type is unrecognized
            color: "text-gray-500",
          };

          return (
            <div
              key={index}
              className={`w-fit py-1.5 rounded-lg flex items-center justify-center`}
            >
              {type},
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return <div>Loading historical data sends...</div>;
  }

  if (historicalData.length === 0) {
    return <div>No historical data sends found for this recipient.</div>;
  }

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
        <tr className={`bg-slate-100 text-sm font-semibold text-slate-500`}>
          <td className="py-1 pl-6 flex items-center">
            <img src={RecieveIcon} className={"mr-3 h-5 w-5"}/>
            Send
          </td>
          <td className="py-1">Types</td>
          <td className="py-1">ID</td>
          <td className="py-1 pr-6">Packet Size</td>
        </tr>
        </thead>
        <tbody>
          {historicalData.map((data) => (
              <tr
                  key={data.id}
                  className={`cursor-default border-b h-16`}
              >
                <td className={"flex items-center pl-6 space-x-3"}>
                  {renderStatusIcon(data.Status)}
                  <div className="flex-col flex h-16 justify-center text-sm">
                    <div className="font-semibold">{recipientName} Data Send</div>
                    <div className="font-medium opacity-50">Sent on {data.Date}</div>
                  </div>
                </td>
                <td className="py-3 w-1/5 text-sm font-medium">
                  {renderTypes(data.Types)}
                </td>
                <td className="py-3 w-1/5 text-sm font-medium opacity-40">{data.id}</td>
                <td className="py-3 pr-6 w-1/5 text-sm font-medium opacity-40">{data.SendSize} MB</td>
              </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataSendTable;
