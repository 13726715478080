import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import LockList from "../../../components/layout/LockTable";
import { useOrganization } from "../../../context/OrgContext";

const Locks = () => {
    const { focusedOrg } = useOrganization();
    const location = useLocation();

    // Check if the current route is the root of `/app/locks`
    const isRootPath = location.pathname === "/app/locks";

    return (
        <div className={"flex-grow h-full flex"}>
            <div className={"no-scrollbar flex-grow h-full relative"}>
                {isRootPath ? (
                    // Render LockList when on the root path
                    <div className={"relative w-full h-full overflow-y-auto flex flex-col"}>
                        <LockList orgId={focusedOrg} />
                    </div>
                ) : (
                    // Render Outlet when on a nested route
                    <div className={"relative w-full h-full overflow-y-auto"}>
                        <Outlet />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Locks;
