import {Outlet, useNavigate} from "react-router-dom";
import LeftNav from "../../components/common/LeftNav";
import {useAuth} from "../../context/AuthContext";

const Entry = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const handlePageChange = (page) => {
        navigate(`/app/${page}`);
    };

    return (
        <div className={"w-screen h-screen overflow-clip flex fixed"}>
            <div className={"invisible w-0 md:visible md:w-fit"}>
                <LeftNav onPageChange={handlePageChange} />
            </div>
            <div className={"flex-grow h-full"}>
                <Outlet />
            </div>
        </div>
    );
};

export default Entry;
