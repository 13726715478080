import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { fetchSites } from '../../services/firestoreService';
import { point, centroid, distance } from '@turf/turf';
import CustomDropdown from "./MapsContextDropdown";
import {useOrganization} from "../../context/OrgContext";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Mapbox3DPlaceholder = () => {
  const {focusedOrg} = useOrganization();
  const mapContainerRef = useRef(null);
  const [sites, setSites] = useState([]);
  useEffect(() => {
    const getSites = async () => {
      const siteList = await fetchSites(focusedOrg);
      setSites(siteList);
    };

    getSites();
  }, [focusedOrg]);

  useEffect(() => {
    if (sites.length === 0) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-73.1790, 44.0080],
      zoom: 16,
      pitch: 60,
      bearing: -17.6,
    });

    map.on('load', async () => {
      const highlightedBuildings = [];

      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb',
        'tileSize': 512,
        'maxzoom': 14
      });

      map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      map.setLight({
        anchor: 'viewport',
        color: 'white',
        intensity: 0.5
      });

      for (const site of sites) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(site.Address)}.json?access_token=${mapboxgl.accessToken}`
        );
        const data = await response.json();

        if (data.features && data.features.length > 0) {
          const siteCoordinates = data.features[0].geometry.coordinates;

          if (siteCoordinates && siteCoordinates.length === 2) {
            map.queryRenderedFeatures({
              layers: ['building']
            }).forEach(feature => {
              let buildingCentroid;
              if (feature.geometry.type === 'Polygon') {
                const buildingPolygon = {
                  type: 'Feature',
                  geometry: feature.geometry,
                };
                buildingCentroid = centroid(buildingPolygon).geometry.coordinates;
              } else {
                buildingCentroid = feature.geometry.coordinates;
              }

              if (buildingCentroid && buildingCentroid.length === 2 && typeof buildingCentroid[0] === 'number' && typeof buildingCentroid[1] === 'number') {
                if (distance(point(siteCoordinates), point(buildingCentroid), { units: 'meters' }) < 50) {
                  highlightedBuildings.push(feature.id);
                }
              }
            });
          }
        }
      }

      console.log('Highlighted Buildings:', highlightedBuildings);

      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': [
            'case',
            ['in', ['id'], ['literal', highlightedBuildings]],
            'rgb(97,134,211)',
            '#aaa'
          ],
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6,
        }
      });

    });

    return () => map.remove();
  }, [sites]);



  return (
    <div className={"flex-grow h-full relative"}>
      <div className={"absolute h-fit w-full z-10 px-3 py-2 flex justify-between"}>
        <CustomDropdown />
        <div className={"flex flex-col space-y-2 w-10 h-fit"}>
          <div className={"w-full aspect-square bg-white rounded shadow-lg"}></div>
          <div className={"w-full h-40 bg-white rounded shadow-lg"}></div>
        </div>
      </div>
      <div ref={mapContainerRef} className="w-full h-full"/>
    </div>
  );
};

export default Mapbox3DPlaceholder;
