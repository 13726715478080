import React from "react";

const TimeBar = ({ openTime, closeTime, currentTime = new Date() }) => {
    // Helper function to calculate percentage positions
    const calculatePosition = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return ((hours * 60 + minutes) / (24 * 60)) * 100;
    };

    // Calculate positions for open time, close time, and current time
    const openPosition = calculatePosition(openTime);
    const closePosition = calculatePosition(closeTime);
    const currentPosition =
        ((currentTime.getHours() * 60 + currentTime.getMinutes()) / (24 * 60)) * 100;

    return (
        <div className="h-4 w-full">
            {/* Background bar representing 24 hours */}
            <div className="relative w-full h-1 rounded-full bg-slate-100">
                {/* Open period bar */}
                <div
                    className="absolute h-full bg-green-500 rounded-full"
                    style={{
                        left: `${openPosition}%`,
                        width: `${closePosition - openPosition}%`,
                    }}
                ></div>

                {/* Current time marker */}
                <div className="absolute h-4 w-0.5 bg-slate-500 rounded-b-full"
                    style={{
                        left: `${currentPosition}%`,
                    }}
                ></div>
            </div>
            {/* Time labels */}
            <div className="justify-between flex w-full">
                <div className="text-2xs text-slate-400 font-medium z-10 bg-[#ffffff50] rounded">00:00</div>
                <div className="text-2xs text-slate-400 font-medium z-10 bg-[#ffffff50] rounded">23:59</div>
            </div>
        </div>
    );
};

export default TimeBar;
