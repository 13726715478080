import React from "react";
import SecurityIcon from "../../assets/icons/LockIconBlack.svg";
import SalesIcon from "../../assets/icons/SalesIconBlack.svg"; // Example icon for Sales

const NavToolTip = ({ dataTypes, focusedType }) => {
  // Define a mapping of data types to their respective icons and labels
  const dataTypeIcons = {
    Security: { icon: SecurityIcon, label: "Security Data" },
    Sales: { icon: SalesIcon, label: "Sales Data" },
    // Add more data types and their respective labels/icons here
  };

  return (
    <div className="relative w-fit h-fit flex flex-col space-y-2">
      {dataTypes && dataTypes.length > 0 ? (
        dataTypes.map((type, index) => {
          const dataType = dataTypeIcons[type] || {
            icon: SecurityIcon,
            label: "Unknown Data",
          }; // Default to SecurityIcon if type is missing
          return (
            <div
              key={index}
              className="relative group w-fit h-fit p-1 rounded hover:bg-slate-200 transition cursor-pointer"
            >
              {/* Icon */}
              <img
                src={dataType.icon}
                alt={`${type} Icon`}
                className="h-4 w-4"
              />
              {/* Tooltip */}
              <div className="absolute z-40 left-full top-1/2 transform -translate-y-1/2 ml-2 hidden group-hover:block bg-white border text-slate-400 text-xs px-2 py-1 rounded shadow-lg whitespace-nowrap">
                Receives {dataType.label}
              </div>
            </div>
          );
        })
      ) : (
        <div>No data types available</div>
      )}
    </div>
  );
};

export default NavToolTip;
