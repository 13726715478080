import React, { useState } from 'react';
import CheckMarkBlack from "../../assets/icons/CheckMarkBlack.svg";

const Dropdown = ({ icon, options, onSelect, functionName, isOpen, onToggle }) => {
    const [selectedOption, setSelectedOption] = useState("Unselected");

    const handleSelect = (option) => {
        setSelectedOption(option.label);
        onSelect(option);
        onToggle(); // Close dropdown after selection
    };

    return (
        <div className="z-10 w-fit relative">
            <div
                className={`border text-sm text-black rounded-lg transition p-2 flex flex-col space-y-2 cursor-pointer ${
                    isOpen ? 'bg-white' : 'bg-white hover:shadow'
                }`}
                onClick={onToggle}
            >
                <div className={"flex space-x-2 items-center group"}>
                    <img className="h-4 w-4" src={icon} alt="Dropdown" />
                    <h1 className={"flex-grow h-full text-sm font-semibold py-0.5"}>{functionName}</h1>
                    {selectedOption !== "Unselected" && (
                    <div className={"w-full h-fit bg-slate-100 text-sm text-slate-500 font-medium transition group-hover:text-slate-800 rounded px-1.5 flex justify-start"}>
                        <h1 className={""}>{selectedOption}</h1>
                    </div>
                )}
                </div>
                {isOpen && (
                    <div className="w-40 overflow-y-auto absolute top-9 right-0 z-20 shadow rounded-md overflow-clip">
                        <ul className="bg-white border border-slate-200 rounded-md shadow-lg w-full p-1">
                            {options.map((option) => (
                                <li
                                    key={option.value}
                                    className="cursor-pointer px-2 py-1 hover:bg-gray-100 rounded font-medium text-sm text-slate-600 flex justify-between items-center transition"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.label}
                                    {option.label === selectedOption && (
                                        <img
                                            className={"w-4 h-4 opacity-50"}
                                            src={CheckMarkBlack}
                                            alt={"icon"}
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
