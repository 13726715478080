import React, {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRight from "../../assets/icons/RightChevronBlack.svg";
import PlusIcon from "../../assets/icons/PlusBlack.svg";
import EyeIcon from "../../assets/icons/DetailsBlack.svg"
import { ReactComponent as OwnerAccess } from "../../assets/icons/OwnerIcon.svg";
import { ReactComponent as StudentAccess } from "../../assets/icons/StudentIcon.svg";
import { ReactComponent as PublicAccess } from "../../assets/icons/PublicIcon.svg";
import TimeBar from "../specific/TimeBar";
import {fetchEntriesForItem} from "../../services/firestoreService"
import {useOrganization} from "../../context/OrgContext";
import LockTrafficAndAccessCharts from "../specific/LockDetailsCharts";
import LockTrafficChart from "../specific/LockDetailsCharts";
import LogComponent from "./LogComponent";

const LocksDetailsPage = () => {
    const {focusedOrg} = useOrganization();
    const location = useLocation();
    const navigate = useNavigate();
    const { item } = location.state || {};
    const [trafficLevel, setTrafficLevel] = useState(null); // Store traffic level
    const [loading, setLoading] = useState(true); // Track loading state
    const [popData, setPopData] = useState(null);

    useEffect(() => {
      const analyzeTrafficLevel = async () => {
        try {
          // Fetch the entries for the lock
          const entries = await fetchEntriesForItem(focusedOrg, item.Site, item.id);
          setPopData(entries)

          // Calculate traffic level
          const level = getTrafficLevelText(entries);
          setTrafficLevel(level); // Update state with the computed traffic level
        } catch (error) {
          console.error("Error fetching or analyzing traffic level:", error);
          setTrafficLevel("Unknown");
        } finally {
          setLoading(false); // Stop loading once processing is complete
        }
      };

      analyzeTrafficLevel();
    }, [focusedOrg, item]); // Refetch if `focusedOrg` or `item` changes

    if (!item) {
        return <div>No details available</div>;
    }

    const getAccessLevelIcon = (AccessLevel) => {
        if (AccessLevel === "Primary") {
          return <OwnerAccess />;
        } else if (AccessLevel === "Secondary") {
          return <StudentAccess />;
        } else {
          return <PublicAccess />;
        }
      };

    const getAccessLevelTextColor = (AccessLevel) => {
        if (AccessLevel === "Primary") {
          return "text-[#DAA520]";
        } else if (AccessLevel === "Secondary") {
          return "text-[#C04040]";
        } else {
          return "text-[#2D9D59]";
        }
      };

    const getAccessLevelBGColor = (AccessLevel) => {
        if (AccessLevel === "Primary") {
          return "bg-[#DAA520]";
        } else if (AccessLevel === "Secondary") {
          return "bg-[#C04040]";
        } else {
          return "bg-[#2D9D59]";
        }
      };

    // Helper to calculate traffic level
    const getTrafficLevelText = (entries) => {
        const count = entries.length;

        if (count > 50) {
          return "High";
        } else if (count > 20) {
          return "Moderate";
        } else if (count > 0) {
          return "Low";
        } else {
          return "None";
        }
    };

    // Helper for traffic level text color
    const getTrafficLevelTextColor = () => {
      switch (trafficLevel) {
          case "High":
            return "text-red-600";
          case "Moderate":
            return "text-yellow-600";
          case "Low":
            return "text-green-600";
          case "None":
            return "text-gray-600";
          default:
            return "text-gray-400";
      }
    };

    const getTrafficLevelBGColor = () => {
      switch (trafficLevel) {
          case "High":
            return "bg-red-600";
          case "Moderate":
            return "bg-yellow-600";
          case "Low":
            return "bg-green-600";
          case "None":
            return "bg-gray-600";
          default:
            return "bg-gray-400";
      }
    };

    const getTrafficLevelIcon = () => {
      switch (trafficLevel) {
          case "High":
            return <img src={ChevronRight} className={"h-4 w-4 -rotate-90"}/>;
          case "Moderate":
              return <div className={"h-1 w-5 rounded-full bg-black"}/>;
          case "Low":
              return <img src={ChevronRight} className={"h-4 w-4 rotate-90"}/>;
          case "None":
              return <img src={PlusIcon} className={"h-5 w-5 scale-150 rotate-45"}/>;
          default:
              return "bg-gray-400";
      }
    };

    const getTimeLeft = (openTime, closeTime) => {
      const now = new Date();

      // Parse open and close times into Date objects
      const [closeHour, closeMinute] = closeTime.split(":").map(Number);
      const [openHour, openMinute] = openTime.split(":").map(Number);

      const todayClose = new Date(now);
      todayClose.setHours(closeHour, closeMinute, 0, 0);

      const tomorrowOpen = new Date(now);
      tomorrowOpen.setDate(tomorrowOpen.getDate() + 1); // Set to next day
      tomorrowOpen.setHours(openHour, openMinute, 0, 0);

      if (now < todayClose) {
        // If it's before closing time
        const diff = todayClose - now;
        return `${formatTimeDiff(diff)} until Locked`;
      } else {
        // If it's after closing time
        const diff = tomorrowOpen - now;
        return `${formatTimeDiff(diff)} until Active`;
      }
    };

    // Helper to format time difference in HH:mm format
    const formatTimeDiff = (diff) => {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return `${hours}h ${minutes}m`;
    };

    const renderLockStatus = (openTime, closeTime, currentTime = new Date()) => {
        if (!openTime || !closeTime) {
            console.error('OpenTime or CloseTime is undefined');
            return (
                <div className={"w-full space-x-2 pl-2 h-fit flex items-center"}>
                    <div className={"w-1.5 h-1.5 bg-slate-700 rounded-full"}></div>
                    <div className={"text-sm font-medium text-slate-700"}>N/A</div>
                </div>
            );
        }

        const [openHour, openMinute] = openTime.split(':').map(Number);
        const [closeHour, closeMinute] = closeTime.split(':').map(Number);

        const openDate = new Date(currentTime);
        openDate.setHours(openHour, openMinute, 0, 0);

        const closeDate = new Date(currentTime);
        closeDate.setHours(closeHour, closeMinute, 0, 0);

        const isOpen = currentTime >= openDate && currentTime < closeDate;

        return (
        <>
            {isOpen ? (
                <div className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                    <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                        <div className="h-2/3 opacity-80 w-0.5 bg-green-600 rounded-full"></div>
                        <div className="text-3xl text-green-600 font-semibold">Active</div>
                    </div>
                    <div className="relative flex justify-center items-center h-fit">
                        <div className="absolute animate-ping bg-green-600 opacity-50 w-2.5 h-2.5 rounded-full"></div>
                        <div className="absolute bg-green-600 w-2.5 h-2.5 opacity-80 rounded-full"></div>
                    </div>
                </div>
            ) : (
                <div className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                    <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                        <div className="h-2/3 opacity-80 w-0.5 bg-slate-600 rounded-full"></div>
                        <div className="text-3xl text-slate-600 font-semibold">Locked</div>
                    </div>
                    <div className="relative flex justify-center items-center h-fit">
                        <div className="absolute bg-slate-600 w-2.5 h-2.5 opacity-80 rounded-full"></div>
                    </div>
                </div>
            )}
        </>
    )};


    return (
        <div className="px-6 py-2 bg-slate-50 min-h-screen flex flex-col font-Poppins">
            <div className={"w-full rounded-lg border h-fit p-2 bg-white flex"}>
                <div className="flex items-center space-x-0.5">
                    <div className="py-0.5 px-1 flex transition rounded items-center cursor-default">
                        <h1 className="text-sm font-semibold text-slate-500">Devices</h1>
                    </div>
                    <img src={ChevronRight} className={"h-2 w-2 opacity-30"}/>
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center"
                         onClick={() => navigate(-1)}>
                        <h1 className="text-sm font-semibold text-slate-700">Locks</h1>
                    </div>
                    <img src={ChevronRight} className={"h-2 w-2 opacity-30"}/>
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                        <h1 className="text-sm font-semibold text-slate-700">{item.id}</h1>
                    </div>
                </div>
            </div>
            <div className={"w-full h-fit rounded-lg border p-4 bg-white flex flex-col mt-4"}>
                <div className={"flex w-full justify-between pb-2"}>
                    <div className={"flex space-x-4"}>
                        <div className={"text-2xl font-semibold"}>{item.Name}</div>
                        <div className={"border rounded-full text-base font-regular text-slate-400 border-slate-300 px-2 content-center"}>{item.siteName}</div>
                    </div>
                    <div className={"border border-slate-200 bg-slate-50 hover:bg-slate-100 rounded w-32 flex items-center justify-center space-x-1 group cursor-pointer transition"}>
                        <img className={"h-5 w-5 opacity-50 group-hover:opacity-80 transition"} src={EyeIcon}/>
                        <div className={"opacity-50 group-hover:opacity-80 font-medium text-sm transition"}>Show Details</div>
                    </div>
                </div>

                <div className={"flex-row space-x-4 flex"}>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Status</div>
                            <div
                                className={"text-slate-400 font-regular"}>{getTimeLeft(item.OpenTime, item.CloseTime)}</div>
                        </div>
                        {renderLockStatus(item.OpenTime, item.CloseTime)}
                        <TimeBar openTime={item.OpenTime} closeTime={item.CloseTime}/>
                    </div>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Access Level</div>
                            <div className={"text-slate-400 font-regular"}></div>
                        </div>
                        <div
                            className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                            <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                                <div
                                    className={`h-2/3 ${getAccessLevelBGColor(item.AccessLevel)} opacity-80 w-0.5 rounded-full`}></div>
                                <div
                                    className={`text-3xl ${getAccessLevelTextColor(item.AccessLevel)} font-semibold`}>{item.AccessLevel}</div>
                            </div>
                            <div className="flex justify-center items-center h-fit scale-125">
                                {getAccessLevelIcon(item.AccessLevel)}
                            </div>
                        </div>
                        <div className={"h-4 w-full"}>
                            <div className={"text-sm font-medium text-slate-400"}>35 Members Have Access</div>
                        </div>
                    </div>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Current Traffic</div>
                            <div className={"text-slate-400 font-regular"}></div>
                        </div>
                        <div
                            className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                            <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                                <div className={`h-2/3 ${getTrafficLevelBGColor()} opacity-80 w-0.5 rounded-full`}></div>
                                <div
                                    className={`text-3xl ${getTrafficLevelTextColor()} font-semibold`}>{trafficLevel}</div>
                            </div>
                            <div className="flex justify-center items-center h-fit scale-125 opacity-65">
                                {getTrafficLevelIcon()}
                            </div>
                        </div>
                        <div className={"h-4 w-full flex justify-between"}>
                            <div className={"text-sm font-medium text-slate-400"}>Average: 45 Scans / Hour</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"h-fit w-full flex flex-col mt-6"}>
                {popData ? <LogComponent pops={popData} type={"Locks"} Name={item.Name}/> : <div className={""}>Loading</div> }
            </div>
        </div>
    );
};

export default LocksDetailsPage;
