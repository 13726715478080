import React from "react";
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from 'react-router-dom';
import {BsGoogle} from "react-icons/bs";

const SignIn = () => {
    const provider = new GoogleAuthProvider();

    const handleGoogleSignIn = async () => {
        try {
          const result = await signInWithPopup(auth, provider);
          console.log("User Info:", result.user);
        } catch (error) {
          console.error("Google Sign-In error:", error);
        }
    };

    return (
        <button onClick={handleGoogleSignIn} className={"w-full flex items-center justify-center space-x-2 bg-indigo-500 text-white max-w-md p-4 rounded-lg font-medium font-Poppins transition hover:shadow-lg hover:bg-indigo-600"}>
            <BsGoogle />
            <div>Continue with Google</div>
        </button>
    );
};

export default SignIn;
