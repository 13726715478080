import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Adjust this import according to your firebase config file's location and export
import { doc, getDoc } from 'firebase/firestore';
import LoadingSpinner from "../common/Loading";
import RightArrow from "../../assets/icons/UpArrowBlack.svg"

const Plans = ({ orgId }) => {
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState('');

    useEffect(() => {
        const fetchPlan = async () => {
            const docRef = doc(db, "organizations", orgId); // Adjust "organizations" to your collection name
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setSelectedPlan(docSnap.data().Plan); // Ensure 'plan' is the correct field in the document
                } else {
                    console.log("No such document!");
                    setSelectedPlan('Free'); // Default to Free if no plan is found
                }
            } catch (error) {
                console.error("Error fetching plan:", error);
            } finally {
                setLoading(false);
            }
        };

        if (orgId) {
            fetchPlan();
        }
    }, [orgId]);

    if (loading) {
        return <div className="w-full h-full flex flex-col bg-slate-50 font-Poppins">
            <div className={"h-full w-full flex space-x-3"}>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div
                        className={`w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4`}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}></h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}></h1>
                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}></h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}></h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Free" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}></h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}></h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}></h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}></h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Max" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}></h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}></h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}></h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}></h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Enterprise" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }

    return (
        <div className="w-full h-full flex flex-col bg-slate-50">
            <div className={"h-full w-full flex space-x-3"}>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={`w-full h-52 bg-white ${selectedPlan === "Free" ? "" : ""} rounded-lg shadow-lg flex flex-col p-4`}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Free plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for small teams &
                                    individuals</h1>
                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$0</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Free" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                                {selectedPlan === "Free" ? "Current plan" : "Upgrade plan"}
                            </div>
                        </div>
                    </div>
                    <div className={"p-4 flex flex-col space-y-6 justify-center"}>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            50 Members
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            4 Recipients
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            3 Views
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Weekly Data Sends
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            15 MB Data Size
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Max plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for growing teams.</h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$20</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Max" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                            {selectedPlan === "Max" ? "Current plan" : "Upgrade plan"}
                            </div>
                        </div>
                    </div>
                    <div className={"p-4 flex flex-col space-y-6 justify-center"}>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            500 Members
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            12 Recipients
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            6 Views
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Daily Data Sends
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            50 MB Data Size
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Enterprise plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for large organizations.</h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$50</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={`rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs  transition  ${selectedPlan === "Enterprise" ? "bg-white border border-slate-100 cursor-default" : "bg-blue-600 cursor-pointer hover:bg-blue-500 text-white"}`}>
                            {selectedPlan === "Enterprise" ? "Current plan" : "Upgrade plan"}
                            </div>
                        </div>
                    </div>
                    <div className={"p-4 flex flex-col space-y-6 justify-center"}>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Unlimited Members
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Unlimited Recipients
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Unlimited Views
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            Instant Data Sends
                        </div>
                        <div className={"font-semibold opacity-75 flex items-center space-x-1"}>
                            <img src={RightArrow} className={"h-5 w-5 rotate-90"}/>
                            No Data Limits
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default Plans;

