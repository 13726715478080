import React, { useRef, useEffect, useState } from "react";
import ReaderImage from "../../assets/images/ReaderImage.png";
import StandImage from "../../assets/images/Stand.png";
import PlatformImage from "../../assets/images/PlatformImage.png";
import GlassCageMobile from "../../assets/images/GlassCage.png";
import BgLines from "../../assets/images/bg-line1.svg"
import RightArrow from "../../assets/icons/RightChevronWhite.svg"
import Person1 from "../../assets/images/person1.svg"
import Person2 from "../../assets/images/person2.svg"

const descriptions = [
  {
    title: "Magnifier",
    text: "Mooslix revolutionizes access control with cutting-edge biometric verification technology. Experience the future of security with Mooslix.",
    buttonText: "Discover",
    buttonLink: "",
  },
  {
    title: "Terminal",
    text: "Mooslix elevates payment processing with its advanced biometric authentication technology. Embrace the future of payments with Mooslix.",
    buttonText: "Explore",
    buttonLink: "",
  },
  {
    title: "Web Platform",
    text: "Offering unmatched accuracy and speed, Mooslix ensures secure and seamless access management.",
    buttonText: "Try Now",
    buttonLink: "",
  },
  {
    title: "Mobile App",
    text: "Ensuring both speed and security, Mooslix offers a seamless transaction experience.",
    buttonText: "See More",
    buttonLink: "",
  },
];

const photos = [ReaderImage, StandImage, PlatformImage, GlassCageMobile];

const StickyScrollingComponent = () => {
  const containerRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isPhoneSize, setIsPhoneSize] = useState(false);

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = window.scrollY - containerRef.current.offsetTop;
      const maxScroll = containerRef.current.scrollHeight - window.innerHeight;
      const progress = Math.min(Math.max(scrollTop / maxScroll, 0), 1);
      setScrollProgress(progress);
    }
  };

  const navigateToHardware = () => {

  }

  const updateLayout = () => {
    const isPhone = window.matchMedia("(max-width: 768px)").matches;
    setIsPhoneSize(isPhone);
  };

  useEffect(() => {
    updateLayout(); // Initial check
    window.addEventListener("resize", updateLayout);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateLayout);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isPhoneSize) {
    return (
      <div className="w-full">
        {descriptions.map((desc, index) => (
            <div key={index} className="w-full h-screen flex flex-col">
              {/* Stacked layout for phones */}
              <div className="w-full h-1/2 flex items-center justify-center">
                <img
                    src={photos[index]}
                    alt={`Photo ${index + 1}`}
                    className="object-contain w-full h-full p-2"
                />
              </div>
              <div className={`w-full h-1/2 flex flex-col justify-center px-6 bg-gradient-to-b from-transparent to-slate-100 ${photos[index + 1] === undefined ? "" : "border-b border-slate-200"}`}>
                <h1 className="text-3xl font-bold font-Poppins w-full text-center">{desc.title}</h1>
                <p className="text-lg mt-4 font-Poppins w-full text-center">{desc.text}</p>
              </div>
            </div>
        ))}
      </div>
    );
  }

  // Layout for larger screens
  return (
      <div
          ref={containerRef}
          className="relative w-full"
          style={{height: `${(descriptions.length) * 100}vh`}}
      >
        {/* Sticky Container */}
        <div className="sticky top-0 w-full h-screen flex">
        {/* Descriptions Section */}
        <div className="w-1/2 h-screen overflow-hidden bg-blue-200">

          <div className={"absolute h-screen w-screen flex items-center justify-center bg-white"}>
            <div className={"w-full h-full relative flex items-center justify-center"}>
              <img src={BgLines} className={"w-full h-full object-cover opacity-30 rotate-180"}/>
              <div className={"absolute bg-gradient-to-br from-white from-[3%] to-transparent w-full h-full"}/>
              <div className={"absolute h-auto w-auto top-32 bottom-48 left-32 right-32 xl:left-64 xl:right-64 justify-center p-4 items-center flex bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40"}>
                <div className={"text-6xl font-Poppins font-semibold max-w-lg text-center items-center flex flex-col"} onClick={() => navigateToHardware()}>
                  <h1>Technologies to fit your every need.</h1>
                  <a href={"/hardware"} className={"bg-black rounded-md text-white text-lg font-Poppins font-medium px-4 py-2 mt-4 hover:cursor-pointer flex w-fit group"}>
                    Explore
                    <img src={RightArrow} className={"ml-2 transition transform group-hover:translate-x-0.5"}/>
                  </a>
                </div>
              </div>
              <div className={"absolute top-16 left-16 xl:left-56 w-64 h-40 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40"}>
                <img src={Person1} className={"w-full h-full object-contain"}/>
              </div>
              <div
                  className={"absolute bottom-40 right-16 xl:right-72 w-64 h-40 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40"}>
                <img src={Person2} className={"w-full h-full object-contain"}/>
              </div>
            </div>
          </div>


          <div
              className="relative"
              style={{
                transform: `translateY(calc(${scrollProgress * -100}% + ${
                      ((1) / descriptions.length) * 100
                  }%))`,
              }}
          >

            {descriptions.map((desc, index) => (
                <div
                    key={index}
                    className={`h-screen flex flex-col justify-center px-12 bg-slate-50`}
                >
                  <h1 className="text-4xl md:text-7xl font-bold text-black font-Poppins">
                    {desc.title}
                  </h1>
                  <p className="mt-6 text-lg md:text-4xl opacity-30 font-light text-black font-Poppins">
                    {desc.text}
                  </p>
                </div>
            ))}
          </div>
        </div>

          {/* Photos Section */}
          <div className="w-1/2 h-screen overflow-hidden">
            <div
                className="relative overflow-clip"
                style={{
                  transform: `translateY(calc(${scrollProgress * 100}% - ${
                      ((descriptions.length) / descriptions.length) * 100
                  }%))`,
                }}
            >
              {photos.toReversed().map((photo, index) => (
                  <div
                      key={index}
                      className={`h-screen flex items-center justify-center p-6 bg-white ${photos[index + 1] === undefined ? "rounded-b-xl" : ""} ${index === 0 ? "rounded-t-xl" : ""}`}
                  >
                    <img
                        src={photo}
                        alt={`Photo ${index + 1}`}
                        className="object-cover w-full h-auto"
                    />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default StickyScrollingComponent;
