import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MooslixLogoBlack from "../../assets/images/MooslixLogoBlack.svg";
import MooslixLogoTextBlack from "../../assets/images/MooslixLogoTextBlack.svg";
import SignUpHand from "../../assets/images/SignUpHand.png";
import GoogleSignInButton from "../../components/specific/GoogleSignInButton";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/app/dashboard");
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      alert("Failed to log in");
      console.error("Login error:", error);
    }
  };

  return (
    <main className="w-screen h-screen flex justify-center items-center bg-gray-100">
      <section className="w-full h-full flex shadow-lg rounded-lg overflow-hidden bg-white">
        <div className="w-full md:w-3/5 flex flex-col p-8">
          <div className="flex items-center mb-8">
            <a className="flex items-center" href="/">
              <img className="h-10" src={MooslixLogoBlack} alt="Logo" />
              <img className="h-10 ml-2" src={MooslixLogoTextBlack} alt="Mooslix" />
            </a>
          </div>
          <div className="flex flex-col items-center justify-center flex-grow">
            <h1 className="text-4xl font-semibold mb-4">Welcome Back,</h1>
            <p className="text-lg text-gray-500 mb-8">
              Enter your details to log into your Mooslix account.
            </p>
            <GoogleSignInButton />
            <div className="h-0.5 w-full bg-slate-100 rounded-full max-w-lg my-4" />
            <form className="w-full max-w-md" onSubmit={handleSubmit}>
              <input
                className="w-full p-4 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                className="w-full p-4 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <button
                className="w-full py-4 bg-primary text-white font-semibold rounded-lg hover:bg-primary-dark transition duration-300"
                type="submit"
              >
                Log In
              </button>
            </form>
          </div>
        </div>
        <div className="hidden md:block md:w-2/5 relative bg-primary text-white p-8 flex flex-col justify-center items-center">
          <img
            className="absolute -bottom-20 inset-x-0 w-full h-full object-cover opacity-30"
            src={SignUpHand}
            alt="Sign Up"
          />
          <div className="relative text-center z-10">
            <h2 className="text-4xl font-medium mb-4">Secure Your Future</h2>
            <p className="text-lg">with Advanced Biometrics</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
