import React, { useEffect, useState } from "react";
import { useOrganization } from "../../context/OrgContext";
import { fetchOrganizationData } from "../../services/firestoreService";
import { fetchAllRecipients } from "../../services/firestoreService";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import DestinationsIcon from '../../assets/icons/Destination.svg';
import SearchIcon from "../../assets/icons/SearchBlack.svg";
import PlusIcon from "../../assets/icons/PlusBlack.svg";
import CheckMarkIcon from "../../assets/icons/CheckMarkWhite.svg";

const RecipientHub = () => {
  const { focusedOrg } = useOrganization();
  const [allRecipients, setAllRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]); // For search results
  const [orgRecipients, setOrgRecipients] = useState([]);
  const [recipientLogos, setRecipientLogos] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(""); // Track search input

  useEffect(() => {
    const fetchData = async () => {
      if (focusedOrg) {
        try {
          const [orgData, recipients] = await Promise.all([
            fetchOrganizationData(focusedOrg),
            fetchAllRecipients(),
          ]);
          setOrgRecipients(orgData?.Recipients || []);
          setAllRecipients(recipients);

          // Fetch logos for recipients
          const storage = getStorage();
          const logoPromises = recipients.map(async (recipient) => {
            if (recipient.Logo) {
              try {
                const logoRef = ref(storage, `recipient_logos/${recipient.Logo}`);
                const logoUrl = await getDownloadURL(logoRef);
                return { id: recipient.id, logoUrl };
              } catch (error) {
                console.error(`Error fetching logo for recipient ${recipient.id}:`, error);
                return { id: recipient.id, logoUrl: null };
              }
            } else {
              return { id: recipient.id, logoUrl: null };
            }
          });

          const logoResults = await Promise.all(logoPromises);
          const logos = logoResults.reduce((acc, curr) => {
            acc[curr.id] = curr.logoUrl;
            return acc;
          }, {});
          setRecipientLogos(logos);

          // Initialize filteredRecipients with allRecipients
          setFilteredRecipients(recipients);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [focusedOrg]);

  // Filter recipients based on search query
  useEffect(() => {
    const lowerQuery = searchQuery.toLowerCase();
    const filtered = allRecipients.filter((recipient) =>
      recipient.Name?.toLowerCase().includes(lowerQuery) || // Match Name
      recipient.Description?.toLowerCase().includes(lowerQuery) // Match Description
    );
    setFilteredRecipients(filtered);
  }, [searchQuery, allRecipients]);

  if (loading) {
    return <div className="flex-grow h-full bg-slate-50 flex items-center justify-center">Loading recipients...</div>;
  }

  if (!focusedOrg) {
    return <div className="flex-grow h-full bg-slate-50 flex items-center justify-center">No organization selected</div>;
  }

  return (
    <div className="flex-grow h-full bg-slate-50">
      <div className="w-full h-full flex flex-col xl:px-56 lg:px-24 md:px-8 py-16 items-center space-y-3">
        <h1 className="text-5xl font-bold font-Poppins">Recipients</h1>
        <h2 className="text-1xl font-medium font-Poppins opacity-50 max-w-md text-center">
          Integrate all of your AI tools all in one place for simple and secure platform onboarding.
        </h2>
        <div className="text-1xl font-medium items-center space-x-2 flex font-Poppins w-full h-fit bg-white border shadow-md px-3 rounded-xl py-3">
          <img src={SearchIcon} className="opacity-30 h-5 w-5" alt="Search" />
          <input
            type="text"
            placeholder="Search Recipients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-grow outline-none bg-transparent font-medium text-sm text-gray-800"
          />
        </div>
        <ul className="grid grid-cols-2 gap-4 py-4 w-full">
          {filteredRecipients.map((recipient) => {
            const isHighlighted = orgRecipients.includes(recipient.id);
            const logoUrl = recipientLogos[recipient.id];
            return (
              <li
                key={recipient.id}
                className={`w-full h-28 bg-white border shadow-sm rounded-xl p-2 flex flex-col justify-between ${
                  isHighlighted ? "" : ""
                }`}
              >
                <div className="flex space-x-2 h-full">
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      alt={`${recipient.Name || "Recipient"} Logo`}
                      className="h-full aspect-square rounded-xl"
                    />
                  ) : (
                    <div className="h-10 w-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                      No Logo
                    </div>
                  )}
                  <div className="justify-center flex flex-col">
                    <h2 className="font-semibold text-lg font-Poppins">{recipient.Name || "Unnamed Recipient"}</h2>
                    <p className="text-sm text-gray-600 font-Poppins">{recipient.Description || "Important Stuff"}</p>
                    <button
                      className={`text-base font-medium w-fit group ${
                        isHighlighted
                          ? "text-white bg-green-900 cursor-default px-2 rounded-md"
                          : "text-black opacity-75 cursor-pointer"
                      } font-Poppins flex items-center mt-1.5`}
                    >
                      {isHighlighted ? "Enabled" : "Add Recipient"}
                      <img
                        src={`${isHighlighted ? CheckMarkIcon : PlusIcon}`}
                        className={`${
                          isHighlighted ? "h-5 w-5" : "h-5 w-5 ml-2 group-hover:scale-105 transition"
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RecipientHub;
