import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/App/Dashboard";
import './tailwind.css';
import Signup from "./pages/SignUp/SignUp";
import Security from "./pages/Products/Security";
import Payments from "./pages/Products/Payments";
import Platform from "./pages/Products/Platform";
import Entry from "./pages/App/Entry";
import MemberList from "./components/layout/MembersTable";
import OrganizationSettings from "./components/specific/OrganizationSettings";
import Locks from "./pages/App/Devices/Locks";
import Registers from "./pages/App/Devices/Registers";
import LocksDetailsPage from "./components/layout/LocksDetailsPage";
import RegisterDetailsPage from "./components/layout/RegisterDetailsPage";
import MapsComponent from "./components/specific/MapsComponent";
import DevicesHub from "./components/layout/DevicesHub";
import RecipientHub from "./components/layout/RecipientHub";
import RecipientInterface from "./components/layout/RecipientInterface";
import RecipientsEntry from "./components/layout/RecipientsEntry";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/security" element={<Security />} />
        <Route path="/hardware" element={<Payments />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/platform" element={<Platform />} />

        {/* Protected Nested Routes under /app */}
        <Route
          path="/app"
          element={
            <ProtectedRoute>
              <Entry />
            </ProtectedRoute>
          }
        >
          {/* Nested Routes */}
          <Route index element={<Dashboard />} /> {/* Default page for /app */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="team" element={<MemberList />} />
          <Route path="devices/new" element={<DevicesHub/>} />
          <Route path="locks" element={<Locks />} >
            <Route path="details/:id" element={<LocksDetailsPage />} /> {/* Relative path */}
          </Route>
          <Route path="registers" element={<Registers />} >
            <Route path="details/:id" element={<RegisterDetailsPage />} /> {/* Relative path */}
          </Route>
          <Route path="map" element={<MapsComponent />} />
          <Route path="recipients/new" element={<RecipientHub/>} />
          <Route path="recipients" element={<RecipientsEntry />}>
              <Route path=":id" element={<RecipientInterface />} />
          </Route>
          <Route path="settings" element={<OrganizationSettings />} />
        </Route>

        {/* Fallback for unmatched routes */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
