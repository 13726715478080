import React from "react";
import {useOrganization} from "../../../context/OrgContext";
import PaymentHardwareTable from "../../../components/layout/PaymentHardwareTable";
import {Outlet, useLocation} from "react-router-dom";

const Registers = () => {
    const { focusedOrg } = useOrganization();
    const location = useLocation();

    // Check if the current route is the root of `/app/locks`
    const isRootPath = location.pathname === "/app/registers";

    return (
        <div className={"flex-grow h-full flex"}>
            <div className={"no-scrollbar flex-grow h-full relative"}>
                {isRootPath ? (
                    <div className={"relative w-full h-full overflow-y-auto flex flex-col"}>
                        <PaymentHardwareTable orgId={focusedOrg}/>
                    </div>
                ) : (
                    <div className={"relative w-full h-full overflow-y-auto"}>
                        <Outlet />
                    </div>
                )}
            </div>
        </div>
    );
};
export default Registers;