import React from 'react';
import Select from 'react-select';

const options = [
  {
    value: 'warehouseSupervisor',
    label: 'Access Level',
    description: 'Visualize the security across buildings',
    icon: 'A',
  },
  {
    value: 'mobileRobots',
    label: 'Mobile Robots',
    description: 'Track mobile vehicle jobs only',
    icon: 'R',
    active: true, // Example for active status
  },
  {
    value: 'problemSolver',
    label: 'Problem Solver',
    description: 'Identify the most important map issues',
    icon: 'P',
  },
  {
    value: 'analytics',
    label: 'Analytics',
    description: 'Understand data flow and traffic relations',
    icon: 'A',
  },
];

// Custom styles for the dropdown
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    border: 'none',
    boxShadow: state.isFocused ? '0 0 0 2px #2563eb' : 'none', // Blue focus ring
    padding: '0.5rem 0.75rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F3F4F6', // bg-slate-100 on hover
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    marginTop: '0.25rem',
    width: '100%',
    overflow: 'clip',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E5E7EB' : 'white', // bg-slate-100 on hover
    color: '#374151',
    padding: '0.75rem 1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.875rem',
    fontWeight: '500',
  }),
  indicatorsContainer: () => ({
    display: 'none', // No dropdown arrow
  }),
};

const formatOptionLabel = ({ label, description, icon, active }, { isSelected }) => (
  <div className="flex items-center space-x-2">
    <div
      className={`w-8 h-8 rounded-full text-white flex items-center justify-center font-bold 
      ${isSelected ? 'bg-blue-500' : 'bg-slate-800'}`}
    >
      {icon}
    </div>
    <div className="flex flex-col">
      <span className={`font-medium ${isSelected ? 'text-blue-600' : 'text-slate-900'}`}>{label}</span>
      <span className="text-sm text-slate-500">{description}</span>
    </div>
    {active && (
      <span className="ml-auto bg-yellow-100 text-yellow-600 text-xs font-medium py-0.5 px-2 rounded-full">
        Active
      </span>
    )}
    {isSelected && (
      <span className="ml-auto text-blue-600">
        ✔
      </span>
    )}
  </div>
);

const CustomDropdown = () => {
  const handleSelectChange = (selectedOption) => {
    console.log(`Selected: ${selectedOption.label}`);
  };

  return (
    <div className="w-full max-w-sm">
      <Select
        options={options}
        onChange={handleSelectChange}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

export default CustomDropdown;