import React, {useEffect} from 'react';
import { ReactTyped as Typed } from 'react-typed';
import Footer from "../../components/common/Footer";
import Navigation from "../../components/common/Nav";
import MiddleburyIcon from "../../assets/images/MiddleburySeal220.gif";
import DemoUser from "../../assets/images/DemoUser.png";
import HomeIcon from "../../assets/icons/DashboardBlack.svg";
import SitesIcon from "../../assets/icons/SitesBlack.svg";
import DevicesIcon from "../../assets/icons/DevicesBlack.svg";
import MembersIcon from "../../assets/icons/MembersBlack.svg";
import UsageIcon from "../../assets/icons/UsageIcon.svg";
import POSIcon from "../../assets/icons/AlertsBlack.svg";
import AnalyticsIcon from "../../assets/icons/AnalyticsBlack.svg";
import SettingsIcon from "../../assets/icons/SettingsBlack.svg";
import SupportIcon from "../../assets/icons/SupportBlack.svg";
import SearchIcon from "../../assets/icons/SearchBlack.svg";
import BGLines from "../../assets/images/bg-line1.svg";
import RightChevronWhite from "../../assets/icons/RightChevronWhite.svg"
import BGSphere from "../../assets/images/bg-sphere1.png"
import OwnerIcon from "../../assets/icons/OwnerIcon.svg"
import StudentIcon from "../../assets/icons/StudentIcon.svg"
import GuestIcon from "../../assets/icons/PublicIcon.svg"
import HighBattery from "../../assets/icons/HighBattery.svg"
import MediumBattery from "../../assets/icons/MedBattery.svg"
import LowBattery from "../../assets/icons/LowBattery.svg"


export default function Platform(){
    return(
        <div className="w-screen h-fit overflow-clip">
          <div className="w-full h-full">
              <Navigation/>
              <div className={"md:h-screen h-fit w-full bg-white flex md:flex-row flex-col pt-20 relative"}>
                  <div className={"absolute w-full h-full top-0 left-0 opacity-25"}>
                      <img src={BGLines}/>
                  </div>
                  <div className={"md:w-1/2 w-full md:px-12 py-24 flex flex-col justify-between md:h-full h-152"}>
                      <h1 className={"text-6xl font-semibold relative px-10"}>
                          Your One-Stop Shop for {}
                          <Typed
                            strings={[
                              "Monitoring Site Security",
                              "Getting Detailed Usage Insights",
                              "Managing Your Devices",
                              "Analyzing Organization Behavior Flows",
                            ]}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                          />
                      </h1>
                      <a href="/login"  className={"z-10"}>
                          <button className={"w-fit h-fit px-4 py-2 bg-black mx-12 rounded-lg text-white cursor-pointer hover:opacity-60  transition space-x-1 flex items-center"}>
                              <h1>Try Now</h1>
                              <img className={"h-2.5 w-2.5"} src={RightChevronWhite}/>
                          </button>
                      </a>
                  </div>
                  <div className={"md:w-1/2 w-full h-full relative flex items-center md:pb-0 pb-10"}>
                      <div className={"absolute bg-gradient-to-r from-transparent to-white w-1/2 h-full right-0 z-20 from-10%"}></div>
                      <div className="ml-4 overflow-clip w-full md:h-136 h-152 flex min-w-96 mt-12 cursor-default relative bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40">
                          <div className="w-0 invisible md:w-36 md:visible border-r border-white border-opacity-40 flex flex-col p-2">
                              <div className="flex w-full justify-between mt-2">
                                  <div className="flex items-center space-x-1">
                                      <img className="h-4 w-4 rounded" src={MiddleburyIcon} alt="Middlebury Icon"/>
                                      <h1 className="text-2xs font-medium">Middlebury</h1>
                                  </div>
                                  <img className="h-4 w-4 rounded-full" src={DemoUser} alt="Demo User"/>
                              </div>
                              <div className="flex flex-col w-full flex-grow py-6 space-y-1.5">
                                  <div
                                      className="flex space-x-1 opacity-75 items-center pl-1.5 py-0.5 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-md shadow-lg border border-white border-opacity-40">
                                      <img className="h-4 w-4 mt-0.5" src={HomeIcon} alt="Home Icon"/>
                                      <h1 className="text-xs">Home</h1>
                                  </div>
                                  <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                      <img className="h-4 w-4 mt-0.5" src={SitesIcon} alt="Sites Icon"/>
                                      <h1 className="text-xs">Sites</h1>
                                  </div>
                                  <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                      <img className="h-4 w-4 mt-0.5" src={DevicesIcon} alt="Devices Icon"/>
                                      <h1 className="text-xs">Devices</h1>
                                  </div>
                                  <div className="flex flex-col pt-4">
                                      <h1 className="text-2xs opacity-50 font-medium">SECURITY</h1>
                                      <div className="w-full h-fit py-2 space-y-1.5 flex flex-col">
                                          <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                              <img className="h-4 w-4 mt-0.5" src={MembersIcon} alt="Members Icon"/>
                                              <h1 className="text-xs">Access</h1>
                                          </div>
                                          <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                              <img className="h-4 w-4 mt-0.5" src={UsageIcon} alt="Members Icon"/>
                                              <h1 className="text-xs">Usage</h1>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="flex flex-col pt-4">
                                      <h1 className="text-2xs opacity-50 font-medium">PAYMENTS</h1>
                                      <div className="w-full h-fit py-2 space-y-1.5 flex flex-col">
                                          <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                              <img className="h-4 w-4 mt-0.5" src={POSIcon} alt="Analytics Icon"/>
                                              <h1 className="text-xs">Point of Sale</h1>
                                          </div>
                                          <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                              <img className="h-4 w-4 mt-0.5" src={AnalyticsIcon} alt="Analytics Icon"/>
                                              <h1 className="text-xs">Analytics</h1>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="flex flex-grow w-full"></div>
                                  <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                      <img className="h-4 w-4 mt-0.5" src={SettingsIcon} alt="Settings Icon"/>
                                      <h1 className="text-xs">Settings</h1>
                                  </div>
                                  <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                      <img className="h-4 w-4 mt-0.5" src={SupportIcon} alt="Support Icon"/>
                                      <h1 className="text-xs">Support</h1>
                                  </div>
                              </div>
                          </div>
                          <div className="flex-grow flex flex-col h-full py-1.5 space-y-4 overflow-y-clip mt-2">
                              <div className="w-full h-fit flex justify-between px-0 md:px-8">
                                  <div className="w-fit h-fit flex flex-col">
                                      <h1 className="text-xl font-semibold">Welcome, Ricky</h1>
                                      <h1 className="text-md font-medium opacity-50 text-slate-900">Administrator</h1>
                                  </div>
                                  <div className="w-fit h-fit">
                                      <h1 className="text-lg font-semibold text-slate-400 mt-1">July 12th</h1>
                                  </div>
                              </div>
                              <div className="overflow-y-clip h-120 w-full space-y-4 px-0 md:px-8">
                                  <div
                                      className="w-full h-48 bg-white bg-opacity-30 overflow-clip backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 flex flex-col">
                                      <div className="h-6 w-full flex justify-between text-xs p-3">
                                          <div className="w-fit h-fit flex">
                                              <h1 className="font-medium">Cashflow</h1>
                                              <h1 className="opacity-30 font-medium mx-1.5">•</h1>
                                              <div className="flex cursor-pointer">
                                                  <img className="opacity-30 transition h-4/5" src={AnalyticsIcon}
                                                       alt="Revenue"/>
                                                  <h1 className="opacity-30 transition font-medium">Analytics</h1>
                                              </div>
                                          </div>
                                          <div className="w-fit h-fit flex items-center">
                                              <h1 className="font-medium">$7,120</h1>
                                              <h1 className="opacity-30 font-medium mx-1.5">• Dec 2024</h1>
                                          </div>
                                      </div>
                                      <div className="w-full h-full mt-10 relative scale-110">
                                          <div className="absolute left-1/2 transform -translate-x-1/2 -mt-2 flex">
                                              <h1 className={"text-2xs font-semibold text-blue-900"}>$255.30</h1>
                                          </div>
                                          <div
                                              className="h-1/2 mt-2 border-l opacity-10 border-black border-dashed absolute left-1/2 transform -translate-x-1/2"></div>
                                          <div
                                              className="absolute h-full left-1/2 transform -translate-x-1/2 mt-24 pt-2.5">
                                              <h1 className={"text-2xs font-medium text-black opacity-25 mb-0"}>Dec.
                                                  15</h1>
                                          </div>
                                          <div
                                              className="h-2 w-2 mt-2 rounded-full bg-white border-blue-800 border-2 absolute left-1/2 transform -translate-x-1/2"></div>
                                          <svg
                                              className="w-full h-full"
                                              viewBox="0 0 157 106"
                                              preserveAspectRatio="none"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                          >
                                              <path
                                                  d="M0.548218 5.29462V105.073H156.867V24.2341C153.475 17.9209 145.86 4.46388 142.53 1.14091C138.368 -3.0128 136.98 7.69823 130.043 24.2341C124.493 37.4627 118.173 29.746 115.706 24.2341L103.681 14.3981C101.215 19.6345 95.5418 27.7637 92.5819 18.3895C89.6221 9.01533 82.4074 5.75367 79.17 5.29462C75.2181 9.23193 65.9906 18.5321 60.6958 24.2341C54.0772 31.3616 51.9543 23.5213 46.2098 14.3981C40.4654 5.27487 34.2215 16.2512 30.3503 18.3895C26.479 20.5278 22.6078 10.1216 21.7337 7.69822C20.8595 5.27487 15.7395 8.41098 13.117 7.69822C11.0191 7.12802 10.2478 -0.107535 7.30552 0.265645L0.548218 5.29462Z"
                                                  fill="url(#paint0_linear_3011_811)"
                                              />
                                              <defs>
                                                  <linearGradient id="paint0_linear_3011_811" x1="78.7075" y1="0.244019"
                                                                  x2="78.7075" y2="105.073"
                                                                  gradientUnits="userSpaceOnUse">
                                                      <stop stopColor="#3b82f6"/>
                                                      <stop offset="1" stopColor="#ffffff" stopOpacity="0"/>
                                                  </linearGradient>
                                              </defs>
                                          </svg>

                                      </div>
                                  </div>

                                  <div className="w-full h-32 flex space-x-4">
                                      <div
                                          className="w-1/2 h-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3.5 flex flex-col justify-between">
                                          <div className="text-xs flex">
                                              <h1 className="font-medium mr-1">Total Members</h1>
                                          </div>
                                          <div className={"h-fit w-full flex flex-col"}>
                                              <div className={"flex"}>
                                                  <h1 className={"text-3xl font-medium"}>36</h1>
                                                  <h2 className={"text-2xs text-green-800"}>+12</h2>
                                              </div>
                                              <div className={"flex space-x-1"}>
                                                  <h1 className={"text-xs font-medium opacity-40"}>Last Week: 24</h1>
                                                  <h2 className={"text-2xs text-green-800"}>(+50%)</h2>
                                              </div>
                                          </div>
                                      </div>
                                      <div
                                          className="w-1/2 h-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3.5 flex flex-col justify-between">
                                          <div className="text-xs flex h-fit">
                                              <h1 className="font-medium mr-1">First-Time Users</h1>
                                          </div>
                                          <div className={"h-fit w-full flex flex-col"}>
                                              <div className={"flex"}>
                                                  <h1 className={"text-3xl font-medium"}>12</h1>
                                                  <h2 className={"text-2xs text-green-800"}>+2</h2>
                                              </div>
                                              <div className={"flex space-x-1"}>
                                                  <h1 className={"text-xs font-medium opacity-40"}>Last Week: 10</h1>
                                                  <h2 className={"text-2xs text-green-800"}>(+20%)</h2>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div
                                      className="w-full h-64 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3">
                                      <div className={"h-6 w-full flex justify-between text-xs"}>
                                          <div className={"w-fit h-fit flex"}>
                                              <h1 className={"font-medium"}>Entry Traffic</h1>
                                              <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
                                              <div className={"flex cursor-pointer"}>
                                                  <img className={"opacity-30 transition h-4/5"}
                                                       src={UsageIcon} alt={"Revenue"}/>
                                                  <h1 className={"opacity-30 transition font-medium"}>Usage</h1>
                                              </div>
                                          </div>
                                          <div className={"w-fit h-fit flex items-center"}>
                                              <h1 className={"font-medium"}>1,248 Entries</h1>
                                              <h1 className={"opacity-30 font-medium mx-1.5"}>• 30 Dec</h1>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={"absolute bottom-0 w-full h-16 visible md:invisible bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-t-lg shadow-lg border border-white border-opacity-40 flex flex-col items-center"}>
                              <div className={"flex w-full flex-grow space-x-10 justify-center items-center"}>
                                  <div className={"flex flex-col w-fit h-fit items-center"}>
                                      <img className={"w-4 h-4"} src={HomeIcon} alt={"Home"}/>
                                      <h1 className={"text-2xs opacity-50"}>Home</h1>
                                  </div>
                                  <div className={"flex flex-col w-fit h-fit items-center"}>
                                      <img className={"w-4 h-4"} src={SitesIcon} alt={"Home"}/>
                                      <h1 className={"text-2xs opacity-50"}>Sites</h1>
                                  </div>
                                  <div className={"flex flex-col w-fit h-fit items-center"}>
                                      <img className={"w-4 h-4"} src={DevicesIcon} alt={"Home"}/>
                                      <h1 className={"text-2xs opacity-50"}>Devices</h1>
                                  </div>
                                  <div className={"flex flex-col w-fit h-fit items-center"}>
                                      <img className={"w-4 h-4"} src={SearchIcon} alt={"Home"}/>
                                      <h1 className={"text-2xs opacity-50"}>Search</h1>
                                  </div>
                              </div>
                              <div className={"flex w-20 mb-2 bg-slate-400 rounded-full h-0.5"}></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={"md:h-screen h-fitw-full bg-white flex flex-col relative px-12 pt-20"}>
                  <div className={"pt-20"}>
                      <div className={"w-fit h-fit flex space-x-1 items-center px-10"}>
                          <div className={"w-4 h-2.5 bg-red-500 rounded-full"}></div>
                          <h1 className={"opacity-30 font-medium"}>Organization Control & Management</h1>
                      </div>
                      <h1 className={"text-5xl font-bold pl-10"}>Intuitive Layouts and Controls</h1>
                  </div>
                  <div className={"w-full h-fit flex-grow flex md:flex-row flex-col border-y-2 border-slate-50 mt-6"}>
                      <div className={"md:w-1/2 w-full h-full md:border-r-2 md:border-b-0 border-b-2 border-slate-50 pl-10 pt-6 justify-between flex flex-col relative"}>
                          <div className={"absolute bg-gradient-to-b from-transparent to-white w-full h-10 bottom-0 z-30"}></div>
                          <div className={"absolute bg-gradient-to-r from-transparent to-white h-full w-10 right-0 z-30"}></div>
                          <div className={""}>
                              <h1 className={"text-2xl font-semibold"}>Table Views</h1>
                              <p className={"text-lg font-medium opacity-50"}>Smart tables made for quick editing and
                                  equipped with powerful tools</p>
                          </div>
                          <div className={"h-80 w-full overflow-clip rounded-tl-xl border pt-4 pl-4"}>
                              <div className={"h-full w-full rounded-tl-lg border border-slate-200 bg-gradient-to-br from-slate-100 to-white overflow-clip"}>
                                  <div className={"h-12 w-full border-b flex pl-3.5 items-center"}>
                                      <div className={"w-fit h-fit flex space-x-1 font-medium text-xs text-slate-700"}>
                                          <h1>Security</h1>
                                          <h1>•</h1>
                                          <h1>Devices</h1>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={HighBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2"}>0CPH-H420</h1>
                                          <img className={"h-5 w-5"} src={GuestIcon} alt={"Student Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2"}>Front Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Davis Library</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={HighBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2.5"}>0CPH-V410</h1>
                                          <img className={"h-5 w-5"} src={OwnerIcon} alt={"Owner Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2.5"}>Back Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Davis Library</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={MediumBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2.5"}>0CPH-V310</h1>
                                          <img className={"h-5 w-5"} src={OwnerIcon} alt={"Owner Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2.5"}>Side Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Davis Library</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={LowBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2.5"}>0CPH-V315</h1>
                                          <img className={"h-5 w-5"} src={StudentIcon} alt={"Owner Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2.5"}>Left Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Forest Hall</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={MediumBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2.5"}>0CPH-V316</h1>
                                          <img className={"h-5 w-5"} src={StudentIcon} alt={"Owner Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2.5"}>Right Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Forest Hall</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"h-10 w-full border-b flex pl-2 items-center justify-between"}>
                                      <div className={"flex w-fit h-fit items-center"}>
                                          <img className={"h-5 w-5 opacity-60"} src={MediumBattery} alt={"High Battery"}/>
                                          <h1 className={"text-xs font-medium text-slate-600 ml-1 mr-2.5"}>0CPH-V316</h1>
                                          <img className={"h-5 w-5"} src={StudentIcon} alt={"Owner Access"}/>
                                          <h1 className={"text-xs font-medium text-slate-800 ml-2.5"}>Front Door</h1>
                                      </div>
                                      <div className={"justify-end items-center w-fit h-fit flex space-x-4"}>
                                          <div className={"px-1.5 py-0.5 border rounded-full text-2xs font-medium"}>Peterson Athletics Center</div>
                                          <div className={"flex space-x-1 items-center"}>
                                              <span className={"w-1.5 h-1.5 bg-green-500 rounded-full"}></span>
                                              <div className={"font-medium text-xs text-green-500"}>Open</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className={"md:w-1/2 w-full h-full pl-10 pt-6 justify-between flex flex-col relative"}>
                          <div
                              className={"absolute bg-gradient-to-b from-transparent to-white w-full h-10 bottom-0 z-30"}></div>
                          <div className={"absolute bg-gradient-to-r from-transparent to-white h-full w-10 right-0 z-30"}></div>
                          <div className={""}>
                              <h1 className={"text-2xl font-semibold"}>Smart Widgets</h1>
                              <p className={"text-lg font-medium opacity-50"}>Customize your experience to match how you work best</p>
                          </div>
                          <div className={"h-80 w-full overflow-clip rounded-tl-xl border pt-4 pl-4"}>
                              <div className={"h-full w-full rounded-tl-lg border border-slate-200 bg-gradient-to-br from-slate-100 to-white p-4 flex flex-col space-y-2"}>
                                  <div className={"w-full h-fit flex space-x-2"}>
                                      <div className={"flex-grow h-20 bg-white rounded-xl shadow"}></div>
                                      <div className={"flex-grow h-20 bg-white rounded-xl shadow"}></div>
                                      <div className={"flex-grow h-20 bg-white rounded-xl shadow"}></div>
                                      <div className={"flex-grow h-20 bg-white rounded-xl shadow"}></div>
                                  </div>
                                  <div className={"w-full flex-grow flex space-x-2"}>
                                      <div className={"h-full w-1/2 bg-white rounded-xl shadow"}></div>
                                      <div className={"h-full w-1/2 bg-white rounded-xl shadow"}></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={"h-screen w-full bg-white flex relative md:px-12 pt-20"}>
                  <div className={"w-0 invisible md:visible md:w-1/2 h-full p-10"}>
                      <img src={BGSphere} />
                  </div>
                  <div className={"md:w-1/2 w-full h-full flex flex-col relative overflow-clip"}>
                      <div className={"absolute h-full md:w-32 w-0 invisible md:visible bg-gradient-to-r from-transparent to-white right-0"}></div>
                      <div className={"pt-10 w-full"}>
                          <div className={"w-fit h-fit flex space-x-1 items-center md:px-10"}>
                              <div className={"w-4 h-2.5 bg-primary rounded-full"}></div>
                              <h1 className={"opacity-30 font-medium"}>User Security and Authentication</h1>
                          </div>
                          <h1 className={"text-5xl font-bold md:pl-10"}>Assigned Member Roles</h1>
                      </div>
                      <div className={"mt-20 flex flex-col -space-y-24 w-fit items-center"}>
                          <div className={"w-120 md:ml-10 h-40 border rounded-2xl p-4 bg-slate-50 hover:bg-yellow-50 transition hover:-translate-y-2 shadow-sm cursor-default"}>
                              <div className={"w-full h-fit flex justify-between"}>
                                  <div className={"flex space-x-2"}>
                                      <img src={OwnerIcon}/>
                                      <h1 className={"font-medium"}>Dylan Smith</h1>
                                  </div>
                              </div>
                          </div>
                          <div className={"w-136 md:ml-10 h-48 border rounded-2xl p-4 bg-slate-50 hover:bg-green-50 transition hover:-translate-y-2 z-10 shadow cursor-default"}>
                              <div className={"w-full h-fit flex justify-between"}>
                                  <div className={"flex space-x-2 items-center"}>
                                      <img src={GuestIcon} className={"h-7 w-7"}/>
                                      <h1 className={"font-medium text-lg"}>Ricky Chen</h1>
                                  </div>
                              </div>
                          </div>
                          <div className={"w-152 md:ml-10 h-56 border rounded-2xl p-4 bg-slate-50 hover:bg-red-50 transition hover:-translate-y-2 z-10 shadow-lg cursor-default"}>
                              <div className={"w-full h-fit flex justify-between"}>
                                  <div className={"flex space-x-2 items-center"}>
                                      <img src={StudentIcon} className={"h-10 w-10"}/>
                                      <h1 className={"font-medium text-xl"}>Charith Fernando</h1>
                                  </div>
                                  <div className={"w-fit h-fit bg-black bg-opacity-10 text-lg px-2 py-1 rounded-lg font-medium text-black text-opacity-50"}>Student</div>
                              </div>
                              <div className={""}></div>
                          </div>
                      </div>
                  </div>
              </div>
              <Footer/>
          </div>
        </div>
    )
}
