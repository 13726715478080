import React from "react";
import MiddleburyLogo from "../../assets/images/MiddleburySeal220.gif";
import Plus from "../../assets/icons/PlusBlack.svg"
import StickyScrollingComponent from "./StickyScrollingHome";
import Boxes from "./Boxes";
import Carousel from "./Carousel";
import bglines from "../../assets/images/bg-line1.svg";


export default function LandingBody() {
    return (
        <div className="flex flex-col items-center w-full h-fit px-4 md:px-16 lg:px-32 xl:px-64 py-6 overflow-x-clip">
            <div className={"w-full h-fit mt-48"}>
                <Boxes/>
            </div>
            <div className="w-screen h-fit mt-40">
                <StickyScrollingComponent/>
            </div>
            <div className={"w-screen h-10 flex"}>
                <div className={"bg-gradient-to-b from-slate-50 to-white w-1/2 h-full"}></div>
                <div className={"bg-white w-1/2 h-full"}></div>
            </div>
            <div className={"h-screen w-screen bg-white flex flex-col items-center justify-center"}>
                <Carousel/>
            </div>
        </div>
    );
}
