import React from "react";
import {Outlet, useLocation} from "react-router-dom";

const RecipientsEntry = () => {
    const location = useLocation();

    return (
        <div className={"flex-grow h-full flex"}>
            <div className={"no-scrollbar flex-grow h-full relative"}>
                <div className={"relative w-full h-full overflow-y-auto"}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default RecipientsEntry;