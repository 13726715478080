// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwind.css'; // Add this line to import Tailwind CSS
import AppRoutes from './routes';
import { auth, db } from './firebaseConfig'; // Import the Firebase configuration
import AuthProvider from "./context/AuthContext";
import {OrganizationProvider} from "./context/OrgContext";

// Create a context to provide Firebase instances to the app
const FirebaseContext = React.createContext({ auth, db });

// FirebaseProvider component to wrap the app
const FirebaseProvider = ({ children }) => (
  <FirebaseContext.Provider value={{ auth, db }}>
    {children}
  </FirebaseContext.Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FirebaseProvider>
    <React.StrictMode>
      <AuthProvider>
        <OrganizationProvider>
          <AppRoutes />
        </OrganizationProvider>
      </AuthProvider>
    </React.StrictMode>
  </FirebaseProvider>
);

export { FirebaseContext };
