import React, { createContext, useContext, useEffect, useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useAuth } from "./AuthContext"; // Assuming you have a UserContext for auth state

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const { currentUser } = useAuth(); // Get current user from UserContext
  const [focusedOrg, setFocusedOrg] = useState(null); // Current organization
  const [associatedOrgs, setAssociatedOrgs] = useState([]); // List of organizations
  const [isOrgLoading, setIsOrgLoading] = useState(true); // Loading state for organization data

  const db = getFirestore();

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (!currentUser) {
        setFocusedOrg(null);
        setAssociatedOrgs([]);
        setIsOrgLoading(false);
        return;
      }

      try {
        setIsOrgLoading(true);
        const userRef = doc(db, "user_data", currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const orgs = userData.AssociatedOrgs || [];

          setAssociatedOrgs(orgs);

          if (orgs.length > 0) {
            setFocusedOrg(orgs[0]);
          } else {
            console.warn("No associated organizations found.");
          }
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        setIsOrgLoading(false);
      }
    };

    fetchOrganizations();
  }, [currentUser, db]);


  const switchOrg = (orgId) => {
    setFocusedOrg(orgId);
  };

  const value = {
    focusedOrg,
    associatedOrgs,
    isOrgLoading,
    switchOrg,
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);
