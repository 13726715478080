import React, { useState } from "react";
import RightArrow from "../../assets/icons/RightChevronWhite.svg";
import bglines from "../../assets/images/bg-line2.svg"

const reviewsUpward = [
  {
    name: "John Doe",
    description: "Mooslix transformed our team's workflow seamlessly. The platform provided an intuitive and efficient way to manage access and insights. It has become an indispensable tool for our daily operations.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Jane Smith",
    description: "Cardless access is a game-changer for modern teams. It eliminates the need for physical credentials while maintaining top-notch security. This feature alone has saved us significant time and effort.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Alex Johnson",
    description: "Super impressed with the analytics feature. It offers deep insights into usage patterns and helps optimize our workflows. I would absolutely recommend it to anyone looking for a smart management solution. 10/10.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Emily Davis",
    description: "Mooslix made access control simple and secure. Setting up the system was quick, and its performance has been flawless. It's a solution I can confidently rely on for my organization.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Chris Lee",
    description: "Significant improvements in productivity and security were immediately noticeable. Mooslix is both reliable and incredibly easy to use. It's the perfect addition to our workflow toolkit.",
    profilePhoto: "https://via.placeholder.com/100",
  },
];

const reviewsDownward = [
  {
    name: "Samantha Brown",
    description: "Mooslix is a must-have platform for modern workflows. Its comprehensive tools and seamless integration set it apart from competitors. We've experienced nothing but positive results since implementation.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Michael Carter",
    description: "Mooslix revolutionized our access and payment processes. It offers unparalleled convenience while ensuring top-tier security. I can't imagine going back to our previous system after using this.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Laura Wilson",
    description: "Mooslix simplifies complex systems with ease. Its user-friendly interface makes adoption a breeze for our team. We appreciate how much time and effort it saves us daily.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Mark Evans",
    description: "Mooslix combines efficiency and security into one sleek platform. It has helped us reduce errors and improve operational accuracy. The difference it's made for our team is remarkable.",
    profilePhoto: "https://via.placeholder.com/100",
  },
  {
    name: "Rachel Adams",
    description: "Mooslix is the perfect tool for streamlining operations. It handles complex tasks with minimal input and maximum efficiency. Our team has never been more productive.",
    profilePhoto: "https://via.placeholder.com/100",
  },
];


const Carousel = () => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  return (
    <div className="flex h-screen w-full bg-white relative overflow-clip">
      <img src={bglines} className={"absolute scale-x-[1] opacity-10 z-0"}/>
      {/* Left Section: Carousel */}
      <div className="w-full sm:w-fit space-x-0 sm:space-x-4 flex flex-col sm:flex-row items-center relative overflow-hidden px-5 z-10">
        {/* Column 1: Moving Up */}
        <div
          className="h-96 sm:h-fit w-full sm:w-1/2 flex flex-col animate-scroll-up space-y-4"
          style={{ animationPlayState: hoveredCardIndex !== null ? "paused" : "running" }}
        >
          {reviewsUpward.concat(reviewsUpward).map((review, index) => (
            <div
              key={`up-${index}`}
              className={`p-6 w-full sm:w-80 h-96 flex flex-col hover:shadow-2xl hover:scale-105 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 transition`}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
              <p className="mb-4 text-black font-medium opacity-65 font-Poppins">{review.description}</p>
              <div className="flex items-center mt-auto">
                <img
                  src={review.profilePhoto}
                  alt={review.name}
                  className="w-12 h-12 rounded-full border-2 border-white mr-4"
                />
                <h2 className="text-lg font-bold text-gray-800 font-Poppins">{review.name}</h2>
              </div>
            </div>
          ))}
        </div>

        {/* Column 2: Moving Down */}
        <div
          className={`h-fit w-1/2 flex-col animate-scroll-down space-y-4 right-0 hidden lg:flex`}
          style={{ animationPlayState: hoveredCardIndex !== null ? "paused" : "running" }}
        >
          {reviewsDownward.concat(reviewsDownward).map((review, index) => (
            <div
              key={`down-${index}`}
              className="p-6 w-80 h-96 flex flex-col hover:shadow-xl hover:scale-105 transition bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40"
              style={{
                background: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.4)",
              }}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
              <p className="mb-4 text-black font-medium opacity-65 font-Poppins">{review.description}</p>
              <div className="flex items-center mt-auto">
                <img
                  src={review.profilePhoto}
                  alt={review.name}
                  className="w-12 h-12 rounded-full border-2 border-white mr-4"
                />
                <h2 className="text-lg font-bold text-gray-800 font-Poppins">{review.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right Section: Title and Header */}
      <div className="absolute border-b border-white sm:border-0 top-0 items-center backdrop-filter backdrop-blur-md sm:backdrop-blur-0 bg-gradient-to-b from-white to-transparent sm:from-transparent sm:items-start w-screen h-fit sm:h-auto sm:w-auto sm:static sm:flex-1 flex flex-col justify-center p-12 space-y-6 z-10">
        <h1 className="text-4xl sm:text-6xl font-bold text-black font-Poppins text-center sm:text-start">Take Control of Your Data and Insights</h1>
        <h2 className="text-lg sm:text-2xl font-medium text-black font-Poppins mt-4 opacity-50 text-center sm:text-start">
          Mooslix empowers teams with advanced tools for seamless integration.
        </h2>
        <a href={"/signup"} className="mt-8 font-Poppins w-fit px-4 py-3 sm:px-8 sm:py-4 bg-black hover:bg-[#222222] sm:text-base text-sm text-white font-semibold rounded-lg shadow-lg transition flex items-center group">
          Get Started
          <img src={RightArrow} className={"ml-3 transition transform group-hover:translate-x-0.5"} />
        </a>
      </div>

      <style jsx>{`
        @keyframes scroll-up {
          0% {
            transform: translateY(0%);
          }
          100% {
            transform: translateY(-40%);
          }
        }

        @keyframes scroll-down {
          0% {
            transform: translateY(-40%);
          }
          100% {
            transform: translateY(0%);
          }
        }

        .animate-scroll-up {
          animation: scroll-up 30s linear infinite;
        }

        .animate-scroll-down {
          animation: scroll-down 30s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default Carousel;
