import React, { useEffect, useState } from 'react';
import {
  fetchSites,
  fetchPaymentHardwareBySiteId, fetchAllRegistersBySiteId,
} from '../../services/firestoreService';
import Table from "./Table";
import {useNavigate} from "react-router-dom";
import {FcEmptyBattery, FcHighBattery, FcLowBattery, FcMiddleBattery} from "react-icons/fc";
import highBatteryIcon from "../../assets/icons/HighBattery.svg";
import mediumBatteryIcon from "../../assets/icons/MedBattery.svg";
import lowBatteryIcon from "../../assets/icons/LowBattery.svg";
import PlusIcon from "../../assets/icons/PlusBlack.svg";
import {BiExit} from "react-icons/bi";
import {IoClose} from "react-icons/io5";
import {addDoc, collection} from "@firebase/firestore";
import {db} from "../../firebaseConfig";

const PaymentHardwareTable = ({ orgId }) => {
    const [registers, setRegisters] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

  useEffect(() => {
    const getHardware = async () => {
      const sites = await fetchSites(orgId);
      const hardwarePromises = sites.map(async (site) => {
        const hardware = await fetchAllRegistersBySiteId(orgId, site.id);
        return hardware.map((h) => ({ ...h, siteName: site.Name }));
      });
      const hardwareData = await Promise.all(hardwarePromises);
      setRegisters(hardwareData.flat());
      setLoading(false);
    };

    getHardware();
  }, [orgId]);

  const getBatteryLevelIcon = (batteryLevel) => {
      if (batteryLevel > 75) {
        return <img src={highBatteryIcon} className={"h-5 w-5 scale-105"} />;
      } else if (batteryLevel > 25) {
        return <img src={mediumBatteryIcon} className={"h-5 w-5 scale-105"} />;
      } else if (batteryLevel > 0) {
        return <img src={lowBatteryIcon} className={"h-5 w-5 scale-105"} />;
      } else if (batteryLevel === 0) {
        return <img src={PlusIcon} className={"h-5 w-5 scale-105"} />;
      } else {
        return <FcEmptyBattery/>;
      }
    };

    const fetchSiteNames = async () => {
      // Example fetch function for site names
      const siteData = await fetchSites(orgId); // Replace with actual logic
      return siteData.reduce((acc, site) => {
        acc[site.id] = site.Name;
        return acc;
      }, {});
    };

    const AddRegister = ({ closeMenu }) => {
      const [isVisible, setIsVisible] = useState(false);
      const [Name, setName] = useState(""); // Name input state
      const [selectedSite, setSelectedSite] = useState(""); // Selected site state
      const [loading, setLoading] = useState(false);
      const [sites, setSites] = useState([]);

      useEffect(() => {
        const fetchSitesData = async () => {
          if (!orgId) return;
          try {
            const sitesList = await fetchSites(orgId);
            setSites(sitesList);
            setIsVisible(true); // Trigger animation on mount
          } catch (error) {
            console.error("Error fetching sites:", error);
          }
        };

        fetchSitesData();
      }, [orgId]);


      const handleClose = () => {
        setIsVisible(false); // Trigger closing animation
        setTimeout(() => {
          closeMenu(); // Close menu after animation
          reloadRegisters(); // Reload registers
        }, 300);
      };

      const reloadRegisters = async () => {
        if (!orgId) return;
        try {
          const sites = await fetchSites(orgId);
          const hardwarePromises = sites.map(async (site) => {
            const hardware = await fetchAllRegistersBySiteId(orgId, site.id);
            return hardware.map((h) => ({ ...h, siteName: site.Name }));
          });
          const hardwareData = await Promise.all(hardwarePromises);
          setRegisters(hardwareData.flat());
        } catch (error) {
          console.error("Error reloading registers:", error);
        }
      };


      const handleCreate = async () => {
        if (!Name || !selectedSite) {
          alert("Please fill in all fields.");
          return;
        }

        setLoading(true);

        try {
          // Add a new register to the 'registers' subcollection
          const registersRef = collection(db, `organization_data/${orgId}/sites/${selectedSite}/registers`);

          // Capitalize the Name field
          const capitalizedName = Name.charAt(0).toUpperCase() + Name.slice(1);

          await addDoc(registersRef, {
            Name: capitalizedName,
            BatteryLevel: 100, // Default battery level
            Site: selectedSite,
          });
          handleClose();
        } catch (error) {
          console.error("Error creating register:", error);
          alert("Failed to create register.");
        } finally {
          setLoading(false);
        }
      };

      return (
        <div
          className={`fixed inset-0 bg-[#00000050] h-full w-screen z-50 flex items-center justify-center transition-opacity duration-300 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`bg-white w-96 h-fit rounded-lg shadow-lg flex flex-col transform transition-transform duration-300 overflow-clip ${
              isVisible ? "translate-y-0" : "translate-y-10"
            }`}
          >
            <div className="h-fit w-full border-b py-4 px-4 flex justify-between items-center">
              <h1 className="font-semibold text-slate-700">Add Register</h1>
              <div
                className="w-fit h-fit cursor-pointer hover:bg-slate-100 p-1 rounded flex items-center justify-center transition"
                onClick={handleClose}
              >
                <IoClose />
              </div>
            </div>

            <div className="h-fit w-full px-6 py-6 flex flex-col space-y-4">
              {/* Name Input */}
              <div>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Register Name
                </label>
                <input
                  type="text"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter register name"
                  className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Site Dropdown */}
              <div>
                <label className="block text-sm font-medium text-slate-600 mb-1">
                  Site
                </label>
                <select
                  value={selectedSite}
                  onChange={(e) => setSelectedSite(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="">Select a site</option>
                  {sites.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.Name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="h-fit w-full border-t bg-slate-50 p-4 flex justify-end space-x-2 items-center">
              <button
                className="w-fit h-fit text-slate-700 text-sm font-medium hover:bg-slate-200 py-2 px-4 rounded-lg transition"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className={`w-fit h-fit px-4 text-white py-2 text-sm font-medium rounded-lg transition ${
                  loading ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-500"
                }`}
                onClick={handleCreate}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      );
    };

    const renderCustomRow = (item) => {
        const handleRowClick = () => {
            navigate(`details/${item.id}`, {state: {item}});
        };

        return (
            <tr key={item.id}
                className="w-full cursor-pointer hover:bg-slate-50 transition border-b border-slate-100"
              onClick={handleRowClick}>
              <td className="py-3 w-6 p-2 scale-75"></td>
              <td className="py-3 w-10 p-2 scale-125">{getBatteryLevelIcon(item.BatteryLevel)}</td>
              <td className="py-3 w-24 text-sm opacity-50 uppercase max-w-24 overflow-clip">{item.id.toString().substring(0,4) + "-" + item.id.toString().substring(4,8)}</td>
              <td className="py-3 flex-grow text-sm font-medium pl-2">{item.Name}</td>
              <td className="py-3 flex-grow flex justify-end mr-6">
                  <div className="h-fit w-fit border rounded-full bg-white text-xs py-1 px-2">
                      {item.siteName}
                  </div>
              </td>
          </tr>
      );
  }

    return (
        <Table
            name={"Registers"}
            data={registers}
            loading={loading}
            groupByOptions={[{value: "siteName", label: "Site" }]}
            sortOptions={[{ value: "Name", label: "Name" }]}
            filterOptions={[{ value: "siteName", label: "Site" }]}
            onGroupBy={(option) => console.log("Group By:", option)}
            onSort={(option) => console.log("Sort By:", option)}
            onFilterBy={(option) => console.log("Filter By:", option)}
            renderRow={renderCustomRow} // Pass custom row renderer here
            RenderAdd={AddRegister}
            fetchSiteNames={fetchSiteNames}
          />
  );
};

export default PaymentHardwareTable;

