import React, { useEffect, useState } from 'react';
import {
    fetchOrganizationData,
    fetchOrganizationMembers,
    fetchUsersByIds,
    fetchStorageImage,
    addMemberToOrganization,
    removeMemberFromOrganization,
    checkIfUserIsGuest
} from '../../services/firestoreService';
import Plus from "../../assets/icons/PlusBlack.svg";
import SortIcon from "../../assets/icons/SortBlack.svg";
import FilterIcon from "../../assets/icons/ConfigureIconBlack.svg";
import MembersIcon from "../../assets/icons/MembersBlack.svg";
import GroupIcon from "../../assets/icons/GroupIcon.svg";
import { ReactComponent as OwnerIcon } from "../../assets/icons/OwnerIcon.svg";
import { ReactComponent as StudentIcon } from "../../assets/icons/StudentIcon.svg";
import { ReactComponent as PublicIcon } from "../../assets/icons/PublicIcon.svg";
import { ReactComponent as ManagerIcon } from "../../assets/icons/ManagerIcon.svg";
import Dropdown from '../common/Dropdown';
import SignOutIcon from "../../assets/icons/SignOutBlack.svg"; // Adjust the path as necessary
import Details from '../../assets/icons/DetailsBlack.svg'
import Remove from '../../assets/icons/RemoveRed.svg'
import addMember from '../../assets/icons/AddMember.svg'
import LoadingSpinner from "../common/Loading";
import {useOrganization} from "../../context/OrgContext";
import ChevronRight from "../../assets/icons/RightChevronBlack.svg";
import {useNavigate} from "react-router-dom";
import {IoClose} from "react-icons/io5";
import {CiLock} from "react-icons/ci";
import {collection} from "@firebase/firestore";
import {db} from "../../firebaseConfig";
import {getDocs} from "firebase/firestore";

const MemberList = () => {
    const {focusedOrg, associatedOrgs} = useOrganization();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'status', direction: 'ascending' });
    const [filterConfig, setFilterConfig] = useState({ key: '', value: '' });
    const [groupBy, setGroupBy] = useState('status');
    const [orgDetails, setOrgDetails] = useState(null);
    const [invitations, setInvitations] = useState([]);
    const [orgLogoUrl, setOrgLogoUrl] = useState('');
    const [showAddMember, setShowAddMember] = useState(false);
    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [newMemberStatus, setNewMemberStatus] = useState('');
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [pendingMenuOpen, setPendingMenu] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [userIsGuest, setUserIsGuest] = useState(false);
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    // State for sidebar visibility
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const getOrgDetails = async () => {
            try {
                const details = await fetchOrganizationData(focusedOrg);
                setOrgDetails(details);

                if (details.Logo) {
                    const orgLogoUrl = await fetchStorageImage(details.Logo);
                    setOrgLogoUrl(orgLogoUrl);
                }
            } catch (error) {
                console.error('Error fetching organization details:', error);
            }
        };

        const getMembers = async () => {
            try {
                const { memberIds, statuses } = await fetchOrganizationMembers(focusedOrg);
                const usersData = await fetchUsersByIds(memberIds);
                const membersWithStatus = usersData.map(user => ({
                    ...user,
                    status: statuses[memberIds.indexOf(user.id)]
                }));

                // Fetch profile pictures
                const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                    if (member.ProfileURL) {
                        const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                        return { ...member, ProfilePicUrl: profilePicUrl };
                    }
                    return member;
                }));

                setMembers(membersWithProfilePics);
            } catch (error) {
                console.error('Error fetching members:', error);
            } finally {
                setLoading(false);
            }
        };

        const getInvitations = async () => {
        try {
            const invitationsRef = collection(db, `organization_data/${focusedOrg}/invitations`);
            const invitationSnapshot = await getDocs(invitationsRef);

            const invitationsList = invitationSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setInvitations(invitationsList);
        } catch (error) {
            console.error('Error fetching invitations:', error);
        }
    };

        if (focusedOrg) {
            getOrgDetails();
            getMembers();
            getInvitations();
        }
    }, [focusedOrg]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleFilter = (key, value) => {
        setFilterConfig({ key, value });
    };

    const handleGroupBy = (key) => {
        setGroupBy(key);
    };

    const handleAddMember = async () => {
        if (!newMemberEmail.trim() || !newMemberStatus.trim()) {
            alert("Both Email and Status are required.");
            return;
        }

        try {
            await addMemberToOrganization(focusedOrg, newMemberEmail, newMemberStatus);
            alert("Member added successfully!");
            setShowAddMember(false);
            setNewMemberEmail('');
            setNewMemberStatus('');

            // Refresh the member list
            const { memberIds, statuses } = await fetchOrganizationMembers(focusedOrg);
            const usersData = await fetchUsersByIds(memberIds);
            const membersWithStatus = usersData.map(user => ({
                ...user,
                status: statuses[memberIds.indexOf(user.id)]
            }));

            // Fetch profile pictures
            const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                if (member.ProfileURL) {
                    const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                    return { ...member, ProfilePicUrl: profilePicUrl };
                }
                return member;
            }));

            setMembers(membersWithProfilePics);
        } catch (error) {
            console.error('Error adding member:', error);
            alert("Failed to add member. Please try again.");
        }
    };


    const handleRemoveMember = async (memberId) => {
        try {
            await removeMemberFromOrganization(focusedOrg, memberId);
            setContextMenuVisible(false);
            setSelectedMember(null);
            // Refresh the member list
            const { memberIds, statuses } = await fetchOrganizationMembers(focusedOrg);
            const usersData = await fetchUsersByIds(memberIds);
            const membersWithStatus = usersData.map(user => ({
                ...user,
                status: statuses[memberIds.indexOf(user.id)]
            }));

            // Fetch profile pictures
            const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                if (member.ProfileURL) {
                    const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                    return { ...member, ProfilePicUrl: profilePicUrl };
                }
                return member;
            }));

            setMembers(membersWithProfilePics);
        } catch (error) {
            console.error('Error removing member:', error);
        }
    };

    const handleContextMenu = (e, member) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        setSelectedMember(member);
        setContextMenuPosition({ x: rect.right, y: rect.bottom });
        setContextMenuVisible((prev) => (!(prev && selectedMember === member)));
      };

    const sortedMembers = [...members].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const filteredMembers = sortedMembers.filter(member => {
        if (filterConfig.key && filterConfig.value) {
            return member[filterConfig.key] === filterConfig.value;
        }
        return true;
    });

    const groupedMembers = filteredMembers.reduce((acc, member) => {
        const key = member[groupBy];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(member);
        return acc;
    }, {});

    const renderRoleIcon = (role) => {
        switch (role) {
            case 'owner':
                return <OwnerIcon />;
            case 'admin':
                return <PublicIcon />;
            case 'student':
                return <StudentIcon />;
            case 'faculty':
                return <ManagerIcon />;
            case 'guest':
                return <PublicIcon />;
            default:
                return null;
        }
    };

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            setShowAddMember(false);
        }, 300); // Matches transition duration
    };

    const handleOpenAddMenu = () => {
        setShowAddMember(true); // Show the modal
        setTimeout(() => {
            setIsVisible(true); // Trigger the animation
        }, 100); // Small delay to ensure CSS animations apply
    };



    const renderRoleBg = (role) => {
        switch (role) {
            case 'owner':
                return "bg-gradient-to-r to-slate-50 from-[#DAA52025] to-5%";
            case 'admin':
                return "bg-gradient-to-r to-slate-50 from-[#DAA52025] to-5%";
            case 'student':
                return "bg-gradient-to-r to-slate-50 from-[#F2717125] to-5%";
            case 'faculty':
                return "bg-gradient-to-r to-slate-50 from-[#A189C125] to-5%";
            case 'guest':
                return "bg-gradient-to-r to-slate-50 from-[#2D9D5925] to-5%";
            default:
                return null;
        }
    };


    if (loading) {
        return <div className={"h-full justify-center items-center flex"}>
            <LoadingSpinner />
        </div>;
    }

    return (
        <div className="py-2 bg-slate-50 h-full font-Poppins">
            <div className="px-6 flex justify-between items-center mb-2">
                <div className={"flex-grow rounded-lg border h-fit p-2 bg-white flex"}>
                    <div className="flex items-center space-x-0.5">
                        <div className="py-0.5 px-1 flex transition rounded items-center cursor-default">
                            <h1 className="text-sm font-semibold text-slate-500">Members</h1>
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div
                        className="h-full ml-2 relative rounded-lg flex items-center w-fit text-sm py-2.5 bg-white hover:bg-slate-50 group transition cursor-pointer px-3 font-semibold text-slate-600 border"
                        onClick={() => setPendingMenu(!pendingMenuOpen)}
                    >
                        <div className={"bg-slate-100 group-hover:bg-slate-200 mr-1 text-slate-600 group-hover:text-slate-700 px-1.5 rounded transition"}>{invitations.length}</div>
                        Pending Invites
                    </div>
                    {/* Invitations Dropdown */}
                    {pendingMenuOpen && (
                        <div
                            className="absolute top-full left-2 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg w-64 z-50">
                            {invitations.length > 0 ? (
                                <ul className="py-1">
                                    <div className={"px-4 text-sm py-1 font-medium opacity-50"}>
                                        Pending Invites
                                    </div>
                                    {invitations.map((invitation) => (
                                        <li
                                            key={invitation.id}
                                            className="px-3 mx-1 py-2 text-sm text-gray-700 hover:bg-gray-100 transition cursor-pointer rounded"
                                        >
                                            <div>
                                                <strong>{invitation.Email}</strong>
                                                <p className="text-xs text-gray-500">{invitation.Status}</p>
                                                <p className="text-xs text-gray-400">
                                                    Sent: {new Date(invitation.SentAt).toLocaleDateString()}
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="px-4 py-2 text-sm text-gray-500">No pending invitations</div>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="h-full ml-2 rounded-lg flex items-center w-fit text-sm py-2.5 bg-indigo-600 hover:bg-indigo-500 transition cursor-pointer px-2 font-semibold text-white"
                    onClick={() => handleOpenAddMenu()}
                >
                    <img src={addMember} className="h-4 w-4 scale-125 mr-2"/>
                    Add Member
                </div>
            </div>
            <div className="overflow-x-hidden overflow-y-auto pb-4">
                {Object.keys(groupedMembers).map((key) => (
                    <div key={key}
                         className={"rounded-lg border bg-white flex-grow flex flex-col mt-3 mx-6 overflow-clip shadow-sm"}>
                        <div
                            className={`${renderRoleBg(key)} py-2 text-sm font-semibold px-6 flex items-center border-b`}>
                            {renderRoleIcon(key)}
                            <span
                                className="ml-2 font-semibold text-sm">{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                            <span
                                className="ml-2 text-xs font-semibold bg-slate-200 rounded px-1 text-slate-500">{groupedMembers[key].length}</span>
                        </div>
                        <table className="min-w-full">
                            <tbody>
                            {groupedMembers[key].map((member, index) => (
                                <tr key={member.id}
                                    className={`relative group ${index < groupedMembers[key].length - 1 ? "border-b border-slate-100" : ""} flex items-center`}>
                                    <td className="py-3 border-slate-100 w-10 pl-6">{renderRoleIcon(member.status)}</td>
                                    <td className="py-3 border-slate-100 w-8 pl-1 ml-4 flex items-center">
                                        {member.ProfilePicUrl ? (
                                            <img src={member.ProfilePicUrl} alt="Profile"
                                                 className="w-6 h-6 rounded-full"/>
                                        ) : (
                                            <div className="w-6 h-6 rounded-full bg-slate-100"></div>
                                        )}
                                    </td>
                                    <td className="py-3 border-slate-100 w-64 text-sm font-medium opacity-75 pl-1 ml-0.5">{member.FirstName} {member.LastName}</td>
                                    <td className={"flex-grow"}></td>
                                    <td className="py-3 border-slate-100 w-64 text-sm font-light text-slate-400 pl-2 text-end mr-6">{member.Email}</td>
                                    {!userIsGuest &&
                                        <td className="w-6 mr-7 opacity-0 group-hover:opacity-100 justify-center">
                                            <button
                                                className="group flex w-6 h-6 p-1 justify-between rounded-full hover:bg-slate-100 transition items-center"
                                                onClick={(e) => handleContextMenu(e, member)}
                                            >
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                            </button>
                                        </td>}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
            {showAddMember && (
                <div
          className={`fixed inset-0 bg-[#00000050] h-full w-screen z-50 flex items-center justify-center transition-opacity duration-300 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`bg-white w-96 h-fit rounded-lg shadow-lg flex flex-col transform transition-transform duration-300 overflow-clip ${
              isVisible ? "translate-y-0" : "translate-y-10"
            }`}
          >
            <div className="h-fit w-full border-b py-4 px-4 flex justify-between items-center">
              <h1 className="font-semibold text-slate-700">Add Member</h1>
              <div
                className="w-fit h-fit cursor-pointer hover:bg-slate-100 p-1 rounded flex items-center justify-center transition"
                onClick={handleClose}
              >
                <IoClose />
              </div>
            </div>

              <div className="h-fit w-full px-6 py-6 flex flex-col space-y-4">
                  {/* Name Input */}
                  <div>
                      <label className="block text-sm font-medium text-slate-600 mb-1">
                          Register Email
                      </label>
                      <input
                          type="email"
                          placeholder="Enter member's email"
                          value={newMemberEmail}
                          onChange={(e) => setNewMemberEmail(e.target.value)}
                          className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      />
                  </div>
                  <div>
                      <label className="block text-sm font-medium text-slate-600 mb-1">
                          Set Status
                      </label>
                      <select
                          value={newMemberStatus}
                          onChange={(e) => setNewMemberStatus(e.target.value)} // Use `e.target.value` for the selected value
                          className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                          <option value="">Select an Access Level</option>
                          <option value="Primary">Owner</option>
                          <option value="Secondary">Faculty</option>
                          <option value="Secondary">Student</option>
                          <option value="Public">Guest</option>
                      </select>
                  </div>

              </div>
              <div className="h-fit w-full border-t bg-slate-50 p-4 flex justify-end space-x-2 items-center">
                  <button
                      className="w-fit h-fit text-slate-700 text-sm font-medium hover:bg-slate-200 py-2 px-4 rounded-lg transition"
                      onClick={handleClose}
                  >
                      Cancel
                  </button>
                  <button
                      className={`w-fit h-fit px-4 text-white py-2 text-sm font-medium rounded-lg transition ${
                          loading ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-500"
                }`}
                onClick={handleAddMember}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
            )}
            {contextMenuVisible && (
                <div
                    className="absolute w-40 bg-white shadow-lg rounded-lg z-50 border right-12 overflow-clip"
                    style={{ top: contextMenuPosition.y }}
                >
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-slate-50 transition flex space-x-1 items-center"
                        onClick={() => {
                            alert(`Member Details: ${JSON.stringify(selectedMember, null, 2)}`);
                            setContextMenuVisible(false);
                        }}
                    >
                        <img src={Details} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-slate-700 transition text-sm font-medium"}>Details</h1>
                    </button>
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-red-50 transition flex space-x-1 items-center"
                        onClick={() => handleRemoveMember(selectedMember.id)}
                    >
                        <img src={Remove} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-red-700 transition text-sm font-medium"}>Remove</h1>
                    </button>
                </div>
            )}
        </div>
    );
};

export default MemberList;
