import { saveAs } from "file-saver";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Adjust this to your Firebase config
import {fetchAllEntriesForOrg, fetchAllTransactionsForOrg} from "./firestoreService"; // Import the existing method

const generateCSV = (data, headers) => {
  const rows = [
    headers.join(","),
    ...data.map((row) =>
      headers
        .map((header) => {
          const value = row[header] !== undefined ? String(row[header]) : "";
          // Enclose values containing commas in double quotes
          return value.includes(",") ? `"${value}"` : value;
        })
        .join(",")
    ),
  ];
  return rows.join("\n");
};

const combineArrayFields = (items, amounts, prices) => {
  if (!items || !amounts || !prices) return "";
  return items.map((item, index) => {
    const amount = amounts[index] || "0";
    const price = prices[index] || "0.00";
    return `${item} (${amount} @ $${price})`;
  }).join(", "); // Ensure commas inside the field are enclosed in quotes in the CSV
};


const downloadCSV = (filename, csvContent) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, filename);
};


const exportService = {
  exportData: async (orgId, dataTypes) => {
    for (const type of dataTypes) {
      let headers = [];
      let filename = `${type}_data.csv`;
      let data = [];

      switch (type) {
        case "Sales":
          headers = ["TransactionID", "Date", "User", "Method", "UserLevel", "Items", "Total", "Site"];
          data = await fetchAllTransactionsForOrg(orgId);
          data = data.map(transaction => ({
            TransactionID: transaction.id || "Unknown",
            Date: transaction.Timestamp?.toDate().toLocaleString() || "Unknown", // Convert Firestore Timestamp
            User: transaction.User || "Unknown",
            Method: transaction.PopType || "Unknown",
            UserLevel: transaction.UserLevel || "Unknown",
            Items: combineArrayFields(transaction.ItemsPurchased, transaction.Amounts, transaction.Prices),
            Total: transaction.PurchaseAmount || 0,
            Site: transaction.site || "Unknown"
          }));
          break;
        case "Security":
          headers = ["TransactionID", "Date", "User", "Method", "UserLevel", "Site"];
          data = await fetchAllEntriesForOrg(orgId);
          data = data.map(entry => ({
            TransactionID: entry.id || "Unknown",
            Date: entry.Timestamp?.toDate().toLocaleString() || "Unknown", // Convert Firestore Timestamp
            User: entry.User || "Unknown",
            Method: entry.PopType || "Unknown",
            UserLevel: entry.UserLevel || "Unknown",
            Site: entry.site || "Unknown"
          }));
          break;
        case "Checkins":
          headers = ["Timestamp", "User", "Checkin Point", "Status"];
          // Fetch and map check-in data using the corresponding method
          break;
        default:
          console.error(`Unknown data type: ${type}`);
          continue;
      }

      data.sort((a, b) => a.Site.localeCompare(b.Site)); // Sort by site
      const csvContent = generateCSV(data, headers);
      downloadCSV(filename, csvContent);
    }
  },
};

export default exportService;
