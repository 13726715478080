// src/firestoreService.js
import {db} from '../firebaseConfig'; // Adjust the path as necessary
import {
    addDoc,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where
} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {startOfDay, subDays, format, addHours, eachDayOfInterval} from 'date-fns';
import {getAuth} from "firebase/auth";


export const checkIfUserIsGuest = async (orgId) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        console.error("No user is currently signed in.");
        return false;
    }
    const orgRef = doc(db, "organization_data", orgId);
    const orgDoc = await getDoc(orgRef);

    if (orgDoc.exists()) {
        const orgData = orgDoc.data();

        // Find the index of the current user's UID in the Members array
        const memberIndex = orgData.Members.indexOf(currentUser.uid);

        if (memberIndex !== -1) {
            // Get the status from the Status array at the found index
            const userStatus = orgData.Status[memberIndex];

            // Check if the user's status is "Guest"
            return userStatus.toLowerCase() === "guest";
        } else {
            console.error("User not found in the organization's Members array.");
            return false;
        }
    } else {
        console.error("Organization document does not exist.");
        return false;
    }
};

export const fetchOrganizationData = async (orgId) => {
    const orgRef = doc(db, 'organization_data', orgId);
    const orgDoc = await getDoc(orgRef);
    if (orgDoc.exists()) {
        return orgDoc.data();
    } else {
        throw new Error('No such organization!');
    }
};

export const fetchSites = async (orgId) => {
    const sitesCollection = collection(db, 'organization_data', orgId, 'sites');
    const sitesSnapshot = await getDocs(sitesCollection);
    const siteList = sitesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return siteList;
};

export const fetchOrganizationMembers = async (orgId) => {
    const orgRef = doc(db, 'organization_data', orgId);
    const orgDoc = await getDoc(orgRef);
    if (orgDoc.exists()) {
        const { Members: memberIds, Status: statuses } = orgDoc.data();
        return { memberIds, statuses };
    } else {
        throw new Error('No such organization!');
    }
};

export const fetchUsersByIds = async (userIds) => {
    if (!userIds || userIds.length === 0) return [];

    const usersCollection = collection(db, 'user_data');
    const q = query(usersCollection, where('__name__', 'in', userIds));
    const userSnapshot = await getDocs(q);
    const users = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return users;
};

export const fetchAllLocksBySiteID = async (orgId, siteId) => {
    // Reference to the hardware collection
    const locksCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'locks');

    try {
    // Fetch all documents in the registers collection
    const locksSnapshot = await getDocs(locksCollection);
    const locksList = locksSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return locksList;
  } catch (error) {
    console.error("Error fetching registers:", error);
    throw error;
  }
};

export const fetchAllRegistersBySiteId = async (orgId, siteId) => {
  // Reference to the registers collection
  const registersCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'registers');

  try {
    // Fetch all documents in the registers collection
    const registersSnapshot = await getDocs(registersCollection);
    const registersList = registersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return registersList;
  } catch (error) {
    console.error("Error fetching registers:", error);
    throw error;
  }
};


export const fetchFlows = async (orgId) => {
    const flowsCollection = collection(db, 'organization_data', orgId, 'flows');
    const flowsSnapshot = await getDocs(flowsCollection);
    const flowsList = flowsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return flowsList;
};

export const fetchFlow = async (orgId, flowId) => {
    const flowRef = doc(db, 'organization_data', orgId, 'flows', flowId);
    const flowDoc = await getDoc(flowRef);
    if (flowDoc.exists()) {
        return flowDoc.data();
    } else {
        throw new Error('No such flow!');
    }
};

export const fetchTotalMembers = async (orgId) => {
    const orgRef = doc(db, 'organization_data', orgId);
    const orgDoc = await getDoc(orgRef);
    if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        return orgData.Member_Emails.length || 0;
    } else {
        throw new Error('No such organization!');
    }
};

export const fetchNewMembersThisWeek = async (orgId) => {
    const orgRef = doc(db, 'organization_data', orgId);
    const orgDoc = await getDoc(orgRef);
    if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        return orgData.Member_Emails.length || 0;
    } else {
        throw new Error('No such organization!');
    }
};

export const addSite = async (orgId, siteData) => {
    try {
        const sitesCollectionRef = collection(db, 'organization_data', orgId, 'sites');
        const docRef = await addDoc(sitesCollectionRef, siteData);
        return docRef.id;
    } catch (error) {
        throw new Error('Error adding new site: ' + error.message);
    }
};

export const fetchStorageImage = async (imagePath) => {
    const storage = getStorage();
    const imageRef = ref(storage, imagePath);
    const imageUrl = await getDownloadURL(imageRef);
    return imageUrl;
};

export const addMemberToOrganization = async (orgId, email, status) => {
    try {
        const orgRef = doc(db, 'organization_data', orgId);
        const orgDoc = await getDoc(orgRef);

        if (!orgDoc.exists()) {
            throw new Error('No such organization!');
        }

        const { Members: memberIds = [], Status: statuses = [] } = orgDoc.data();

        const usersCollection = collection(db, 'user_data');
        const q = query(usersCollection, where('Email', '==', email));
        const userSnapshot = await getDocs(q);

        if (!userSnapshot.empty) {
            // User exists, add them to the organization
            const userId = userSnapshot.docs[0].id;

            if (memberIds.includes(userId)) {
                throw new Error('User is already a member of the organization.');
            }

            await updateDoc(orgRef, {
                Members: arrayUnion(userId),
                Status: [...statuses, status],
            });

            return { success: true, message: "User added to the organization." };
        } else {
            // User does not exist, store invitation in Firestore
            const invitationsRef = collection(db, `organization_data/${orgId}/invitations`);
            await addDoc(invitationsRef, {
                Email: email,
                Status: status,
                SentAt: new Date().toISOString(),
            });

            // Simulate sending an email
            console.log(`Invitation sent to ${email} for organization ${orgDoc.data().Name} with status ${status}.`);
            console.log(`Visit: https://example.com/invite?orgId=${orgId}&email=${email}`);

            return { success: true, message: "Invitation created and logged for the user." };
        }
    } catch (error) {
        throw new Error('Error adding member: ' + error.message);
    }
};


export const removeMemberFromOrganization = async (orgId, memberId) => {
    try {
        const orgRef = doc(db, 'organization_data', orgId);
        const orgDoc = await getDoc(orgRef);

        if (!orgDoc.exists()) {
            throw new Error('No such organization!');
        }

        const { Members: memberIds, Status: statuses } = orgDoc.data();

        // Find the index of the member to remove
        const memberIndex = memberIds.indexOf(memberId);
        if (memberIndex === -1) {
            throw new Error('Member not found');
        }

        // Remove the member and their status
        const updatedMemberIds = memberIds.filter(id => id !== memberId);
        const updatedStatuses = statuses.filter((_, index) => index !== memberIndex);

        // Update the organization document
        await updateDoc(orgRef, {
            Members: updatedMemberIds,
            Status: updatedStatuses
        });
    } catch (error) {
        throw new Error('Error removing member: ' + error.message);
    }
};

export const addHardware = async (orgId, siteId, hardwareData) => {
    try {
        const hardwareCollectionRef = collection(db, 'organization_data', orgId, 'sites', siteId, 'hardware');
        const docRef = await addDoc(hardwareCollectionRef, hardwareData);
        return docRef.id;
    } catch (error) {
        throw new Error('Error adding new hardware: ' + error.message);
    }
};

export const removeHardware = async (orgId, siteId, hardwareId) => {
    try {
        const hardwareRef = doc(db, 'organization_data', orgId, 'sites', siteId, 'hardware', hardwareId);
        await deleteDoc(hardwareRef);
    } catch (error) {
        throw new Error('Error removing hardware: ' + error.message);
    }
};

export const fetchPaymentHardware = async (orgId, siteId) => {
  const hardwareCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'hardware');
  const paymentHardwareSnapshot = await getDocs(query(hardwareCollection, where('Type', '==', 'Payments')));
  return paymentHardwareSnapshot.docs.map(doc => ({ id: doc.id, siteId: siteId, ...doc.data() }));
};

export const fetchItemDetails = async (orgId, siteId, ) => {
  const hardwareCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'hardware');
  const paymentHardwareSnapshot = await getDocs(query(hardwareCollection, where('Type', '==', 'Payments')));
  return paymentHardwareSnapshot.docs.map(doc => ({ id: doc.id, siteId: siteId, ...doc.data() }));
};

export const fetchTransactions = async (orgId, siteId, itemId) => {
  const transactionsRef = collection(db, `organization_data/${orgId}/sites/${siteId}/registers/${itemId}/pops`);
    const q = query(transactionsRef);

    try {
        const querySnapshot = await getDocs(q);
        const transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return transactions; // This must return an array
    } catch (error) {
        console.error("Error fetching entries:", error);
        return []; // Return an empty array to handle errors gracefully
    }
};

export const fetchAllTransactionsForOrg = async (orgId) => {
  try {
    if (!orgId) {
      console.error("Organization ID is undefined");
      return [];
    }

    const sitesCollection = collection(db, 'organization_data', orgId, 'sites');
    const sitesSnapshot = await getDocs(sitesCollection);
    const sites = sitesSnapshot.docs.map(doc => doc.id); // Get site IDs

    let allTransactions = [];

    for (const siteId of sites) {
      if (!siteId) {
        console.error(`Site ID is undefined for orgId: ${orgId}`);
        continue;
      }

      const hardwareCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'registers');
      const hardwareSnapshot = await getDocs(hardwareCollection);
      const hardware = hardwareSnapshot.docs.map(doc => doc.id);

      for (const hardwareId of hardware) {
        if (!hardwareId) {
          console.error(`Hardware ID is undefined for siteId: ${siteId}, orgId: ${orgId}`);
          continue;
        }

        const transactionsCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'registers', hardwareId, 'pops');
        const transactionsSnapshot = await getDocs(transactionsCollection);
        const transactions = transactionsSnapshot.docs.map(doc => ({ id: doc.id, site: siteId, ...doc.data() }));
        allTransactions = allTransactions.concat(transactions);
      }
    }

    return allTransactions;
  } catch (error) {
    console.error("Error fetching transactions for organization:", error);
    return [];
  }
};

export const getLastWeekRevenueData = async (orgId) => {
  const today = startOfDay(new Date());
  const sevenDaysAgo = subDays(today, 6);

  const allTransactions = await fetchAllTransactionsForOrg(orgId);

  const weeklyData = allTransactions
    .filter(t => {
      const transactionDate = new Date(t.timestamp.seconds * 1000);
      return transactionDate >= sevenDaysAgo;
    })
    .reduce((acc, curr) => {
      const day = format(new Date(curr.timestamp.seconds * 1000), 'EEE');
      if (!acc[day]) {
        acc[day] = { income: 0, spend: 0 };
      }
      acc[day].income += curr.Amount > 0 ? curr.Amount : 0;
      acc[day].spend += curr.Amount < 0 ? curr.Amount : 0;
      return acc;
    }, {});

  return weeklyData;
};

export const getAllRevenueData = async (orgId) => {
  const today = startOfDay(new Date());

  // Fetch all transactions for the organization
  const allTransactions = await fetchAllTransactionsForOrg(orgId);

  // Find the earliest transaction date
  const earliestTransactionDate = allTransactions.reduce((earliest, transaction) => {
    const transactionDate = new Date(transaction.timestamp.seconds * 1000);
    return transactionDate < earliest ? transactionDate : earliest;
  }, today);

  // Generate all dates from the earliest transaction to today
  const allDates = eachDayOfInterval({
    start: startOfDay(earliestTransactionDate),
    end: today,
  });

  // Initialize an object with all dates set to zero income and spend
  const allTimeData = allDates.reduce((acc, date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    acc[formattedDate] = { income: 0, spend: 0 };
    return acc;
  }, {});

  // Process each transaction to accumulate income and spend per day
  allTransactions.forEach(transaction => {
    const transactionDate = format(new Date(transaction.timestamp.seconds * 1000), 'yyyy-MM-dd');
    if (transaction.Amount > 0) {
      allTimeData[transactionDate].income += transaction.Amount;
    } else {
      allTimeData[transactionDate].spend += transaction.Amount;
    }
  });

  // Return the final data object
  return allTimeData;
};

export const fetchAllEntriesForOrg = async (orgId) => {
  try {
    if (!orgId) {
      console.error("Organization ID is undefined");
      return [];
    }

    const sitesCollection = collection(db, 'organization_data', orgId, 'sites');
    const sitesSnapshot = await getDocs(sitesCollection);
    const sites = sitesSnapshot.docs.map(doc => doc.id); // Get site IDs

    let allEntries = [];

    for (const siteId of sites) {
      if (!siteId) {
        console.error(`Site ID is undefined for orgId: ${orgId}`);
        continue;
      }

      const hardwareCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'locks');
      const hardwareSnapshot = await getDocs(hardwareCollection);
      const hardware = hardwareSnapshot.docs.map(doc => doc.id);

      for (const hardwareId of hardware) {
        if (!hardwareId) {
          console.error(`Hardware ID is undefined for siteId: ${siteId}, orgId: ${orgId}`);
          continue;
        }

        const entriesCollection = collection(db, 'organization_data', orgId, 'sites', siteId, 'locks', hardwareId, 'pops');
        const entriesSnapshot = await getDocs(entriesCollection);
        const entries = entriesSnapshot.docs.map(doc => ({ id: doc.id, site: siteId, ...doc.data() }));
        allEntries = allEntries.concat(entries);
      }
    }

    return allEntries;
  } catch (error) {
    console.error("Error fetching transactions for organization:", error);
    return [];
  }
};

export const filterEntriesFromToday = (entries) => {
  const currentTime = new Date();
  const startOfDay = new Date(currentTime);
  startOfDay.setHours(0, 0, 0, 0); // Set to midnight

  return entries.filter(entry => {
    const entryTime = entry.timestamp.toDate(); // Assuming timestamp is a Firestore Timestamp object
    return entryTime >= startOfDay && entryTime <= currentTime;
  });
};

export const getHourlyRevenueData = async (orgId) => {

    const today = startOfDay(new Date());
    const allTransactions = await fetchAllTransactionsForOrg(orgId);

    // Modify this to fetch transactions for a specific day
    const selectedDate = today; // You can set this to any specific day
    const startOfDayTimestamp = selectedDate.getTime();
    const endOfDayTimestamp = addHours(startOfDayTimestamp, 24).getTime();

    const hourlyData = allTransactions
        .filter(t => {
            const transactionDate = new Date(t.timestamp.seconds * 1000).getTime();
            return transactionDate >= startOfDayTimestamp && transactionDate < endOfDayTimestamp;
        })
        .reduce((acc, curr) => {
            const hour = format(new Date(curr.timestamp.seconds * 1000), 'HH'); // 'HH' for 24-hour format
            if (!acc[hour]) {
                acc[hour] = { income: 0, spend: 0, transactions: 0 }; // Initialize transactions
            }
            acc[hour].income += curr.Amount > 0 ? curr.Amount : 0;
            acc[hour].spend += curr.Amount < 0 ? curr.Amount : 0;
            acc[hour].transactions += 1; // Increment transaction count
            return acc;
        }, {});

    // Return the hourly data
    return hourlyData;
};

export const fetchEntriesForItem = async (orgId, siteId, itemId) => {
    const entriesRef = collection(db, `organization_data/${orgId}/sites/${siteId}/locks/${itemId}/pops`);
    const q = query(entriesRef);

    try {
        const querySnapshot = await getDocs(q);
        const entries = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return entries; // This must return an array
    } catch (error) {
        console.error("Error fetching entries:", error);
        return []; // Return an empty array to handle errors gracefully
    }
};

export const fetchAllRecipients = async () => {
    const recipientsRef = collection(db, 'recipients');
    const querySnapshot = await getDocs(recipientsRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const fetchRecipientsByIds = async (idsArray) => {
    const recipientsRef = collection(db, 'recipients');
    const q = query(recipientsRef, where('__name__', 'in', idsArray));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};


