import React, { useEffect, useState } from "react";
import { useOrganization } from "../../context/OrgContext";
import { fetchOrganizationData } from "../../services/firestoreService";
import DevicesIcon from '../../assets/icons/DevicesBlack.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/PlusBlack.svg';

const DevicesHub = () => {
  const { focusedOrg } = useOrganization();
  const [devices, setDevices] = useState(null);
  const [orgData, setOrgData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (focusedOrg) {
        try {
          const data = await fetchOrganizationData(focusedOrg); // Await the async operation
          setOrgData(data);
          setDevices(data?.Devices || null); // Safely set devices
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      }
    };

    fetchData();
  }, [focusedOrg]);

  if (!focusedOrg) {
    return (
      <div className={"flex-grow h-full bg-slate-50 flex items-center justify-center"}>
        No organization selected
      </div>
    );
  }

  if (!devices) {
    return (
      <div className={"flex-grow h-full bg-slate-50 flex items-center justify-center"}>
        Loading devices...
      </div>
    );
  }

  // Separate and sort devices
  const inUseDevices = Object.entries(devices)
    .filter(([_, value]) => value) // Filter devices with value `true`
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)); // Sort alphabetically

  const tryDevices = Object.entries(devices)
    .filter(([_, value]) => !value) // Filter devices with value `false`
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)); // Sort alphabetically

  return (
    <div className={"flex-grow h-full bg-slate-50"}>
      <div className={"p-4 space-y-4"}>
        <div className={"flex w-full justify-between"}>
          <div className={"flex items-center space-x-2"}>
            <img src={DevicesIcon} className={"h-10 w-10 opacity-80"} />
            <h1 className={"text-3xl opacity-80 font-semibold"}>Devices</h1>
          </div>
          <div className={"flex bg-blue-600 rounded-lg hover:bg-blue-500 text-white items-center px-2 text-sm font-semibold group w-fit cursor-pointer transition"}>
            <PlusIcon style={{ stroke: "white" }} className={"h-6 w-6"} />
            Add New
          </div>
        </div>

        {/* "In Use" Section */}
        {inUseDevices.length > 0 && (
          <>
            <h2 className="text-xl font-semibold text-slate-700">In Use</h2>
            <ul className="space-y-2">
              {inUseDevices.map(([key, value]) => (
                <li key={key} className={"p-4 bg-white rounded-xl border hover:shadow-sm transition cursor-pointer flex justify-between"}>
                  <div className={"flex items-center space-x-2"}>
                    <div className={"font-medium text-xl text-slate-600"}>
                      {key}
                    </div>
                    <div className={"h-full w-fit px-2 bg-green-100 text-green-600 font-semibold text-sm rounded-md flex items-center justify-center text-center"}>
                      {value ? "Enabled" : "Not Enabled"}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {/* "Try" Section */}
        {tryDevices.length > 0 && (
          <>
            <h2 className="text-xl font-semibold text-slate-700 mt-4">Try</h2>
            <ul className="space-y-2">
              {tryDevices.map(([key, value]) => (
                <li key={key} className={"p-4 bg-white rounded-xl border hover:shadow-sm transition cursor-pointer flex justify-between"}>
                  <div className={"flex items-center space-x-2"}>
                    <div className={"font-medium text-xl text-slate-600"}>
                      {key}
                    </div>
                    <div className={"h-full w-fit px-2 bg-red-100 text-red-600 font-semibold text-sm rounded-md flex items-center justify-center text-center"}>
                      {value ? "Enabled" : "Not Enabled"}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default DevicesHub;
